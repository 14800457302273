import { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useTranslations } from '../Components/Translations';
import { usePosApolloApi } from '../posApolloAPI';
import { AUTHORIZATION_LOGOUT, AGENTLOGIN_MUTATION } from '../queries/authorizationSchemas';

const useAuthorization = () => {
    const { fetchPosMutation } = usePosApolloApi();
    const client = useApolloClient();
    const { getTranslations } = useTranslations();
    const [loginData, setLoginData] = useState({ loading: false, data: {} });

    const handleLogOut = (history) => {
        fetchPosMutation({ mutation: AUTHORIZATION_LOGOUT });
        localStorage.clear();
        history.push('/');
    };

    const agentLogin = (variables) => {
        setLoginData({ ...loginData, loading: true })

        return client.mutate({
            mutation: AGENTLOGIN_MUTATION,
            variables,
        }).then((response) => {
                const { agentlogin } = response.data;

                localStorage.setItem('agentName', agentlogin.name);
                localStorage.setItem('agentId', agentlogin.id);
                localStorage.setItem('currency_code', agentlogin.currency_code);
                localStorage.setItem('currency_code', agentlogin.currency_symbol);
                localStorage.setItem('store_id', agentlogin.store_id);
                localStorage.setItem('store_category_id', agentlogin.store_category_id);
                localStorage.setItem('warehouseId', agentlogin.warehouse_id);
                localStorage.setItem('source_code', agentlogin.source_code);
                localStorage.setItem('login_token', agentlogin.login_token);
                localStorage.setItem('store_code', agentlogin.store_code);
                localStorage.setItem('store_name', agentlogin.store_name);
                localStorage.setItem('storage_modes', JSON.stringify(agentlogin.storage_modes));
                localStorage.setItem('storage_mode', agentlogin.storage_modes[0].mode);
                localStorage.setItem('countries', agentlogin.countries);
                localStorage.setItem('agentDetails', JSON.stringify(agentlogin));

                setLoginData({ data: response.data, loading: false });
                
                getTranslations(
                    { store_id: agentlogin.store_id },
                    {
                        'Content-Type': 'application/json',
                        'Seller': agentlogin.login_token,
                        'Storage': agentlogin.store_name,
                        'Storage-Mode': agentlogin.storage_modes[0].mode,
                        'Authorization': 'Bearer ' + agentlogin.login_token,
                        'Store': agentlogin.store_code,
                        'Content-Currency': agentlogin.currency_code,
                    }
                );
        }).catch(() => { setLoginData({ loading: false, data: {} }); });
    };

    return {
        handleLogOut,
        agentLogin,
        loginData,
    };
};

export default useAuthorization;