export default (text, ...params) => {
    const locale = JSON.parse(localStorage.getItem('translations'));
    let result = locale && locale[text] ? locale[text] : text;

    params.forEach((param, index) => {
        result = result.replace(`%${index + 1}`, param);
    });

    return result;
};
