import React from "react";
import ContentLoader from 'react-content-loader';

function OrderSkeleton() {
  return (
        <ContentLoader
            height={200}
            width={305}
            speed={.8}
            primaryColor="#E8E8E8"
            secondaryColor="#FFFFFF"
        >
            <rect height="15" x="5" width="50" y="5" />
            <rect height="15" x="60" width="50" y="5" />
            <rect height="15" x="115" width="50" y="5" />
            <rect height="15" x="170" width="50" y="5" />
            <rect height="15" x="225" width="50" y="5" />
            <rect height="15" x="5" width="295" y="25" />
            <rect height="15" x="5" width="295" y="45" />
            <rect height="15" x="5" width="295" y="65" />
            <rect height="15" x="5" width="295" y="85" />
            <rect height="15" x="5" width="295" y="105" />
            <rect height="15" x="5" width="295" y="125" />
            <rect height="15" x="5" width="295" y="145" />
            <rect height="15" x="5" width="295" y="165" />
            <rect height="15" x="5" width="295" y="185" />

        </ContentLoader>
  		);
}
export default OrderSkeleton;
