import React, { useState } from 'react';
import './App.css';
import Routes from './Routes';

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  
  return (
    <div className="App">
      <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
    </div>
  );
}   
export default App;
