export const formattedPrice = (value, isSymbolRight) => {
    const currencySymbol = localStorage.getItem('currency_code');

    return isSymbolRight
        ? `${value.toFixed(2)} ${currencySymbol}`
        : `${currencySymbol} ${value.toFixed(2)}`;
};

export const orderFormatPrice = (value, orderCurrencySymbol) => {
    const currencySymbol = localStorage.getItem('currency_code');

    return `${value.toFixed(2)} ${orderCurrencySymbol ? orderCurrencySymbol : currencySymbol}`;
};

export const mapCart = ({
    items,
    prices: {
        grand_total,
        subtotal_excluding_tax,
        tax_amount,
        fee,
        discount_amount,
        pos_discount_amount,
    },
    total_quantity,
    applied_coupons,
    amasty_rewards_used_points = {used_points: '0'},
    applied_am_gift_cards = [],
}) => ({
    'base_grand_total': grand_total.value,
    'subtotal': subtotal_excluding_tax.value,
    'base_subtotal': subtotal_excluding_tax.value,
    'coupon_code': applied_coupons && applied_coupons.length ? applied_coupons[0].code : '',
    'discount_amount': discount_amount.value,
    'tax_amount': tax_amount.value,
    'base_currency_code': grand_total.currency,
    'items_qty': total_quantity,
    'items': items.map(({ id, uid, prices, product, options = [], quantity }) => ({
        'item_id': id,
        uid,
        'price': prices.price.value,
        'qty': quantity,
        'row_total': prices.row_total.value,
        'tax_amount': prices.tax_amount.value,
        'discount_amount': prices.total_item_discount.value,
        'price_incl_tax': prices.price_including_tax.value,
        'row_total_incl_tax': prices.row_total_including_tax.value,
        "extension_attributes": {
            options,
            'item_discount': prices.item_discount.value,
            'sku': product.sku,
        },
        'name': product.name,
    })),
    'extension_attributes': {
        'amasty_rewards_highlight': {
            "visible": false,
            "caption_color": "#000000",
            "caption_text": "0"
        },
        'applied_reward_points': amasty_rewards_used_points.used_points,
        'pos_discount': pos_discount_amount ? pos_discount_amount.value : null,
        'applied_gift_cards': applied_am_gift_cards && applied_am_gift_cards.length
            ? applied_am_gift_cards.map(({code, applied_balance, current_balance}) => ({
                'id': code,
                code,
                'amount': applied_balance.value,
                'currentAmount': current_balance.value,
                'cardCurrency': applied_balance.currency,
            })) : [],
    },
    'total_segments': [
        {
            "code": "subtotal",
            "title": "Zwischensumme",
            "value": subtotal_excluding_tax.value,
        },
        {
            "code": "fee",
            "title": "",
            "value": fee.value,
        },
        {
            "code": "discount",
            "title": "Rabatt (550 Prämienpunkte verwendet, Added discount by the seller.)",
            "value": discount_amount.value,
        },
        {
            "code": "shipping",
            "title": "Versand & Bearbeitung",
            "value": 0,
        },
        {
            "code": "tax",
            "title": "Inkl. MwSt. von",
            "value": tax_amount.value,
        },
        {
            "code": "grand_total",
            "title": "Gesamtsumme",
            "value": grand_total.value,
            "area": "footer"
        }
    ],
});
