import React, { useContext } from 'react';
import { Store } from '../../Store';
import './globalMessage.css';

const GlobalMessage = () => {
    const { state } = useContext(Store);
    const modes = JSON.parse(localStorage.getItem('storage_modes')) || [];
    const activeMode = modes.filter(({mode}) => mode === state.storeMode)[0];

    if (!(modes.length && activeMode && activeMode.message)) {
        return null;
    }

    return (
        <div className="global-message-wrap">
            <div className={activeMode.message ? 'warning' : 'message'}>
                {activeMode.message}
            </div>
        </div>
    );
};

export default GlobalMessage;
