import React from 'react';
import PropTypes from 'prop-types';
import GiftCardListItem from './GiftCardListItem';
import './giftCardsList.css';

const GiftCardsList = ({ removeGiftCard }) => {
    const quoteId = localStorage.getItem('quote_id');
	const customerToken = localStorage.getItem('customerToken');
	const cartDetails = quoteId && customerToken ? JSON.parse(localStorage.getItem('cartDetails')) : "";

    if (!(cartDetails
        && cartDetails.extension_attributes
        && cartDetails.extension_attributes.applied_gift_cards
        && cartDetails.extension_attributes.applied_gift_cards.length)) {
        return null;
    }

    return (
        <div className='gift-cards-list'>
            <div className='gift-cards-list-title'>
                Applied Gift Cards:
            </div>
            {
                cartDetails.extension_attributes.applied_gift_cards.map(({ id, code, amount, cardCurrency }) => (
                    <GiftCardListItem
                        key={id}
                        code={code}
                        amount={amount}
                        cardCurrency={cardCurrency}
                        removeGiftCard={removeGiftCard}
                    />
                ))
            }
        </div>
    );
};

GiftCardsList.propTypes = {
    removeGiftCard: PropTypes.func,
};

export default GiftCardsList;
