import React, { useState, useContext } from 'react';
import { useAlert } from 'react-alert';
import { usePosApolloApi } from '../posApolloAPI';
import { Store } from '../Store';
import { GET_REWARD_POINTS, USE_REWARD_POINTS } from '../queries/rewardPointsSchemas';
import useCart from './useCart';

const useRewardPoints = () => {
    const { fetchPosMutation, fetchPosQuery } = usePosApolloApi();
    const { dispatch } = useContext(Store);
    const { getCustomerCart } = useCart();
    const messageAlert = useAlert();
    const [rewardPoints, setRewardPoints] = useState({
        loading: false,
        data: {}
    });

    const getRewardPoints = () => {
        setRewardPoints({ ...rewardPoints, loading: true })

        return fetchPosQuery({
            query: GET_REWARD_POINTS,
            fetchPolicy: "network-only",
            doAction: (response) => {
                const { balance, amount } = response.data.rewards;

                localStorage.setItem('reward_points', JSON.stringify({
                    points: balance,
                    amount,
                }));

                setRewardPoints({ data: {
                    points: balance,
                    amount,
                }, loading: false });
            },
            doError: () => { setRewardPoints({ data: {}, loading: false }) }
        });
    };

    const sendPoints = (points) => {
        dispatch({ type: 'show_cart_loader', payload: true });
    
        return (
            fetchPosMutation({
                mutation: USE_REWARD_POINTS,
                variables: {
                    input: {
                        cart_id: localStorage.getItem('cart_hash'),
                        points,
                    }
                },
                notifyOnNetworkStatusChange: true,
                doAction: (response) => {
                    getCustomerCart();
                    messageAlert.success(<div dangerouslySetInnerHTML={{ __html: response.data.useRewardPoints.response}} />);
                    dispatch({ type: 'show_cart_loader', payload: false });
                },
                doError: points ? () => {
                    localStorage.setItem('reward_points_error', JSON.stringify(true));
                    dispatch({ type: 'show_cart_loader', payload: false });
                } : null,
            })
        );
    };

    return {
        rewardPoints,
        getRewardPoints,
        applyRewardPoints: (points) => sendPoints(points),
        removeRewardPoints: () => sendPoints(0),
    };
};

export default useRewardPoints;