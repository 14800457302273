import React, { useState } from "react";
import PropTypes from 'prop-types';
import Navigation from "../Navigation/Navigation";
import "../Pos/pos.css";

const Search = (props) =>{
    const { onChange, searchValue } = props;
    const [activeToggle, setActiveToggle] = useState('true');

    const toggleSidebar = () => {
        setActiveToggle(!activeToggle);
    }

    return (
        <div>
            <div className={activeToggle ? 'sidebar' : 'sidebar show-sidebar' }>
                <div className="overlay" onClick={toggleSidebar}></div>
                <Navigation onChange={toggleSidebar} />
            </div>
    		<nav className="navbar navbar-expand-lg navbar-light px-0 py-3">
                <div className="container-fluid px-0">
                    <div className="row header-items-wrapper ml-0">
                        <div className="col-lg-1 col-md-2 text-left">
                            <button onClick={toggleSidebar} type="button" id="sidebarCollapse" className="btn bg-white hamburger-icon">
                                <span className="mt-0"></span>
                                <span></span>
                                <span className="mb-0"></span>
                            </button>
                        </div>
                        <div className="col-lg-10 col-md-0 px-0">
                            <div className="search-form-wrapper">
                                <input className="search-bar-custom w-100 px-3"
                                        id="search-box"
                                        type="search"
                                        placeholder="Search here..."
                                        aria-label="Search"
                                        value={searchValue}
                                        onChange={(e) => onChange(e.target.value)}
                                />
                                {!searchValue &&(
                                    <img className="search-img" src="images/item-search.png" alt="search-icon" width="15" height="15" />
                                )}
                                {searchValue &&(
                                <img className="search-crosss-img" src="images/cancel.png" alt="cross" width="15" height="15"
                                        onClick={() => {
                                            document.getElementById('search-box').value="";
                                            onChange(document.getElementById('search-box').value="");

                                }}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

Search.propTypes = {
	onChange: PropTypes.func,
	searchValue: PropTypes.string,
};

export default Search;
