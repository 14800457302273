import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import $ from 'jquery';
import Loader from '../../Loader/Loader';
import useCart from '../../../talons/useCart';
import './productForm.css';

const CREATE_PRODUCT_MUTATION = gql`
    mutation createCustomSale($quote_id: Int!, $product_name: String!, $price: Float!) {
        createCustomSale(
                quote_id: $quote_id
                product_name: $product_name
                price: $price
        ) {
            success
        }
    }
`;

const ProductForm = (props) => {
    const { isOpenPopup, closePopup, text } = props;
    const [createProduct, { loading, error, data }] = useMutation(CREATE_PRODUCT_MUTATION);
    const [productName, setProductname] = useState("");
    const [price, setPrice] = useState("");
    const [requiredProductNameErrorMessage, setRequiredProductNameErrorMessage] = useState("");
    const [validPriceMessage, setValidPriceMessage] = useState("");
    const quoteId = localStorage.getItem('quote_id');
    const storage = localStorage.getItem('source_code');
    const { getCustomerCart } = useCart();
    const productForm = useRef();

    const validateNumber = (num) => isNaN(num);

    const formSubmit = async () => {
        await createProduct({
            variables: {
                quote_id: parseInt(quoteId),
                product_name: productName,
                price: parseFloat(price, 2),
            },
            context: {
                headers: {
                    'Storage': storage,
                    'Content-Currency': localStorage.getItem('currency_code'),
                    'Store': localStorage.getItem('store_code'),
                    'Seller': localStorage.getItem('login_token'),
                },
            },
        });

        getCustomerCart();
    }

    const addProduct = () => {
        let valid = true;
        if (productName) {
            setRequiredProductNameErrorMessage('');
        } else {
            setRequiredProductNameErrorMessage("This is a required field.");
            valid = false;
        }
        if (price) {
            setValidPriceMessage('');
            if (validateNumber(price)) {
                setValidPriceMessage('Please enter a number');
                valid = false;
            }
        } else {
            setValidPriceMessage("This is a required field.");
            valid = false;
        }
        if (valid) {
            formSubmit();
        }
    }

    const handleProductName = (e) => {
        setProductname(e.target.value);
        setRequiredProductNameErrorMessage(e.target.value ? "" : "This is a required field.");
    }

    const handlePrice = (e) => {
        setPrice(e.target.value);
        let msg;
        if(e.target.value && validateNumber(e.target.value)){
            msg = "Please enter a number"
        } else{
            msg = "This is a required field.";
        }
        setValidPriceMessage(e.target.value ? "" : msg);
    }

    useEffect(() => {
        if (data && data.createCustomSale.success) {
            productForm.current.reset();
            setProductname('');
            setPrice('')

            $('#msgSuccess').delay(3000).fadeOut();
        }
    }, [data]);

    return (
        <div className='pos-customer-form-popup'>
            <div className="pos-form-inner-wrap">
                {loading && (
                    <div className="loading">
                        <Loader />
                    </div>
                )}
                {error && error.graphQLErrors.map(({ message }, i) => (
                    <div className="error-message">
                        <img className="mr-2" src="images/warning.png" alt="error-img" width="20" height="20" />
                        <span key={i}>{message}</span>
                    </div>
                ))}
                <div className={"pos-customer-form-inner " + (isOpenPopup ? "open-modal" : "")}>
                    <div className="modal-header pos-modal-header">
                        <h2 className="modal-title">{text}</h2>
                    </div>
                    <div className="modal-body">
                        <form ref={productForm} className="form-body d-flex justify-content-between flex-wrap">
                            <div className="pos-customer-field mb-2">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="productname">Product Name
                                    <span className="required">*</span>
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="productname"
                                            placeholder="Product Name"
                                            type="text"
                                            onChange={handleProductName}
                                        />
                                    </div>
                                </div>
                                {requiredProductNameErrorMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{requiredProductNameErrorMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field mb-2">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="price">Price
                                        <span className="required">*</span>
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="price" placeholder="Price"
                                            type="text"
                                            onChange={handlePrice}
                                        />
                                    </div>
                                </div>
                                {validPriceMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{validPriceMessage}</div>
                                )}
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer pos-modal-footer pt-0">
                        <button className="add-customer-button" onClick={addProduct}>Add Product</button>
                        <button className="close-button" onClick={closePopup}>Close</button>
                    </div>
                </div>
                {data && data.createCustomSale.success && (
                    <div className="success-message" id="msgSuccess">
                        <img className="mr-2" src="images/CORRECT.png" alt="correct-img" width="20" height="20" />
                        {"Custom sale added successfully."}
                    </div>
                )}
            </div>
        </div>
    );
};

ProductForm.propTypes = {
    isOpenPopup: PropTypes.bool,
    closePopup: PropTypes.func,
    text: PropTypes.string,
};

export default ProductForm;
