import React from "react";
import ContentLoader from 'react-content-loader';

function OrderViewSkeleton() {
  return (
        <ContentLoader
            height={200}
            width={305}
            speed={.8}
            primaryColor="#E8E8E8"
            secondaryColor="#FFFFFF"
        >
            <rect height="15" width="70" x="5" y="5" />
            <rect height="15" width="70" x="80" y="5" />
            <rect height="15" width="70" x="155" y="5" />
            <rect height="15" width="70" x="230" y="5" />

            <rect height="15" x="5" width="295" y="35" />
            <rect height="15" x="5" width="295" y="52" />
            <rect height="15" x="5" width="295" y="69" />
            <rect height="15" x="5" width="295" y="87" />
            <rect height="15" x="5" width="295" y="105" />
            <rect height="15" x="5" width="295" y="123" />


        </ContentLoader>
  		);
}
export default OrderViewSkeleton;
