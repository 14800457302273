/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Orders from '../Orders/Orders';
import DailyReportPopup from '../DailyReportPopup';
import DailyReport from '../DailyReport';
import DepositMoney from '../DepositMoney';
import { __ } from '../Translations';
import { useDailySalesIndicator } from '../../talons/useCustom';
import useShift from '../../talons/useShift';
import { Store } from '../../Store';
import '../Pos/pos.css';
import './navigation.css';
// import AddInventory from "../AddInventory/AddInventory";

const Navigation = (props) => {
	//this.node = React.createRef();
	const { onChange } = props;
	const { state: { isShiftOpen }, dispatch } = useContext(Store);
	const [showOrder, setShowOrder] = useState(false);
	const [isShowAllOrders, setIsShowAllOrders] = useState(false);
	const [isOpenShiftPopup, setIsOpenShiftPopup] = useState(false);
	const [isDepositOpen, setIsDepositOpen] = useState(false);
	const { openShift, closeShift } = useShift();
	const { getDailyData } = useDailySalesIndicator();
	// const [addInventory, setAddInventory] = useState(false);
	// if(props.isAuthenticated === false) {
	//    props.history.push("/");
	// }

	// function closeSidebar() {
	//    setActiveToggle(!activeToggle);
	// }

	const closePopup = () => {
        onChange(true);
	};

	const toggleOrderList = () => {
        if (isShowAllOrders) {
			setIsShowAllOrders(false);
		}

		setShowOrder(false);
	};

	// function toggleAddInventory(){
    //     setAddInventory(!addInventory);
    // }

	const handleShowAllOrders = () => {
		setIsShowAllOrders(true);
		setShowOrder(true);
	};

	const handleShift = () => {
		if (isShiftOpen) {
			setIsOpenShiftPopup(true);
		} else {
			openShift();
			dispatch({
				type: 'open_shift',
				payload: true
			});

			if (isOpenShiftPopup) {
				setIsOpenShiftPopup(false);
			}
		}
	};

	useEffect(() => {
		const isOpen = localStorage.getItem('shift');
		if(isOpen || isOpen === 'Open') {
			dispatch({
				type: 'open_shift',
				payload: true,
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="navigation_wrapper">
			{showOrder && <Orders onChange={toggleOrderList} isShowAllOrders={isShowAllOrders} />}
			{
				isOpenShiftPopup && (
					<DailyReportPopup
						onClose={() => setIsOpenShiftPopup(false)}
						closeShift={closeShift}
						getDailyData={getDailyData}
					/>
				)
			}
			{isDepositOpen && <DepositMoney onClose={() => setIsDepositOpen(false)} />}
			{/* {addInventory && <AddInventory onChange={toggleAddInventory}/>} */}
			<nav
				className={showOrder ? "side-bar-navigation order-table-overlay" : "side-bar-navigation"}
				id="sidebar"
			>
				<div className="sidebar-header">
					<span className="side-panel-title">{__('Side Panel')}</span>
					<a href="#" onClick={closePopup} className="close-side" id="close-sidebar">x</a>
				</div>
				<ul className="list-unstyled components">
					{/* <li className="side-nav-list active">
					<a className="side-nav-link" href="/#">Sales</a>
					</li>
					<li className="side-nav-list">
					<a className="side-nav-link" href="/#">Products Report</a>
					</li> */}
					<li className="side-nav-list">
						<a className="side-nav-link" onClick={() => setShowOrder(true)} href="#">{__('Recent Orders')}</a>
					</li>
					<li className="side-nav-list">
						<a className="side-nav-link" onClick={handleShowAllOrders} href="#">{__('All Orders')}</a>
					</li>
					<li className="side-nav-list">
						<a className={`side-nav-link d-flex shift-button`} onClick={handleShift} href="#">
							{isShiftOpen ? __('Close shift') : __('Open shift')}
							{
								isShiftOpen ? (
									<span className="shift-button-close">
										{__('Shift opened')}
									</span>
								) : (
									<span className="shift-button-open">
										{__('Shift closed')}
									</span>
								)
							}
						</a>
					</li>
					{/*<li className="side-nav-list">*/}
					{/*	<a className="side-nav-link" onClick={(e) => setAddInventory(true)} href="#">Add Inventory</a>*/}
					{/*</li>*/}
					{/* <li className="side-nav-list">
					<a className="side-nav-link" href="/#">All Returns</a>
					</li> */}
				</ul>
				<DailyReport />
				<button className="safe-money" onClick={() => setIsDepositOpen(true)}>
					<img src="images/safe.png" alt="safe" />
					{__('Deposit money')}
				</button>
			</nav>
		</div>
	);
};

Navigation.propTypes = {
	onChange: PropTypes.func,
	isShiftOpen: PropTypes.bool,
	handleShiftOpen: PropTypes.func,
};

export default Navigation;
