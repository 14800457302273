import { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { usePosApolloApi } from '../posApolloAPI';
import { Store } from '../Store';
import {
  ADD_SIMPLE_PRODUCT_MUTATION,
  REMOVE_ITEM_FROM_CART,
  UPDATE_QTY_CART,
  DISCOUNT,
  SHIPPING,
  APPLY_GIFT_CARD,
  REMOVE_GIFT_CARD,
  CART,
  APPLY_COUPON_TO_CART,
  REMOVE_COUPON_FROM_CART,
} from '../queries/cartSchemas';
import { useAlert } from 'react-alert';
import { mapCart } from '../Utils';

const useCart = () => {
    const { fetchPosMutation, fetchPosQuery } = usePosApolloApi();
    const { dispatch } = useContext(Store);
    const messageAlert = useAlert();
    const storage = localStorage.getItem('source_code');
    const storageMode = localStorage.getItem('storage_mode');
    const storeCode = localStorage.getItem('store_code');
    const token = localStorage.getItem('customerToken');

    const updateShippingInformation = () => {
        const agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
        const customerDetails = JSON.parse(localStorage.getItem('customerDetail'));
        const [billingFirstName, billingLastName] = customerDetails.name.split(' ');
        const shippingInfoRequestBody = {
            'addressInformation': {
                'shippingAddress': {
                    'region': agentDetails.store_region,
                    'region_id': agentDetails.store_region_id,
                    'country_code': agentDetails.store_country,
                    'street': [
                        agentDetails.store_street
                    ],
                    'telephone': customerDetails.contact_number ? customerDetails.contact_number : 'N/A',
                    'postcode': agentDetails.store_pincode,
                    'city': agentDetails.store_city,
                    'firstname': localStorage.getItem('store_name'),
                    'lastname': 'Store'
                },
                'billingAddress': {
                    'region': agentDetails.store_region,
                    'region_id': agentDetails.store_region_id,
                    'country_code': agentDetails.store_country,
                    'street': [
                        agentDetails.store_street
                    ],
                    'telephone': customerDetails.contact_number ? customerDetails.contact_number : 'N/A',
                    'postcode': agentDetails.store_pincode,
                    'city': agentDetails.store_city,
                    'firstname': billingFirstName,
                    'lastname': billingLastName
                },
                'shipping_method_code': 'posshipping',
                'shipping_carrier_code': 'posshipping'
            }
        };
        if (agentDetails.store_region_id === '0') {
            delete shippingInfoRequestBody['addressInformation']['shippingAddress']['region_id'];
            delete shippingInfoRequestBody['addressInformation']['billingAddress']['region_id'];
        }

        return fetchPosMutation({
            mutation: SHIPPING,
            variables: {
                input: {
                    shipping_address: shippingInfoRequestBody.addressInformation.shippingAddress,
                    billing_address: shippingInfoRequestBody.addressInformation.billingAddress,
                    cart_id: localStorage.getItem('cart_hash'),
                    method_code: shippingInfoRequestBody.addressInformation.shipping_method_code,
                    carrier_code: shippingInfoRequestBody.addressInformation.shipping_carrier_code,
                }
            },
            notifyOnNetworkStatusChange: true,
        });
    };

    const mapCartData = (response) => {
        const data = mapCart(response);
    
        localStorage.setItem('cartDetails', JSON.stringify(data));
        dispatch({ type: 'cart_items', payload: data });

        return data;
    };

    const getCustomerCart = () => {
        return fetchPosQuery({
            query: CART,
            fetchPolicy: 'network-only',
            doAction: (response) => mapCartData(response.data.customerCart),
        });
    };

    const addProductToCart = (details) => {
        dispatch({
            type: 'show_cart_loader',
            payload: true
        });

        return fetchPosMutation({
            mutation: ADD_SIMPLE_PRODUCT_MUTATION,
            variables: {
                input: details,
            },
            doAction: (response) => mapCartData(response.data.addSimpleProductsToCart.cart),
        }).finally(() => {
            dispatch({
                type: 'show_cart_loader',
                payload: false
            });
        });
    };

    const removeProductFromCart = (details) => {
        dispatch({
            type: 'show_cart_loader',
            payload: true
        });

        return fetchPosMutation({
            mutation: REMOVE_ITEM_FROM_CART,
            variables: {
                input: details,
            },
            doAction: (response) => mapCartData(response.data.removeItemFromCart.cart),
            doError: getCustomerCart,
        }).finally(() => {
            dispatch({
                type: 'show_cart_loader',
                payload: false
            });
        });
    };

    const updateQuoteItemQtyInCart = (details, action = () => {}) => {
        dispatch({
            type: 'show_cart_loader',
            payload: true
        });

        return fetchPosMutation({
            mutation: UPDATE_QTY_CART,
            variables: {
                input: details,
            },
            doAction: (response) => {
                action();
                return mapCartData(response.data.updateCartItems.cart);
            },
            doError: () => { dispatch({ type: 'qty_error', payload: true }); },
        }).finally(() => {
            dispatch({
                type: 'show_cart_loader',
                payload: false
            });
        });
    };

    const applyCoupon = (details) => {
        dispatch({
            type: 'show_cart_loader',
            payload: true
        });

        return fetchPosMutation({
            mutation: APPLY_COUPON_TO_CART,
            variables: {
                input: details,
            },
            doAction: (response) => {
                localStorage.setItem('coupon_response', true);
                return mapCartData(response.data.applyCouponToCart.cart);
            },
        }).finally(() => {
            dispatch({
                type: 'show_cart_loader',
                payload: false
            });
        });
    };

    const removeCoupon = (details, action = () => {}) => {
        dispatch({
            type: 'show_cart_loader',
            payload: true
        });

        return fetchPosMutation({
            mutation: REMOVE_COUPON_FROM_CART,
            variables: {
                input: details,
            },
            doAction: (response) => {
                localStorage.removeItem('coupon_response');
                localStorage.setItem('coupon_removed_response', true);
                action();
                return mapCartData(response.data.removeCouponFromCart.cart);
            },
        }).finally(() => {
            dispatch({
                type: 'show_cart_loader',
                payload: false
            });
        });
    };

    const [addDiscount] = useMutation(DISCOUNT, {
        context: {
            headers: {
                'Storage': storage,
                'Storage-Mode': storageMode,
                'Seller': localStorage.getItem('login_token'),
                'Authorization': 'Bearer ' + token,
                'Store': storeCode,
                'Content-Currency': localStorage.getItem('currency_code')
            }
        }
    });

    const applyDiscount = (details) => {
        if (details.discount_value) {
            dispatch({
                type: 'show_cart_loader',
                payload: true
            });

            return fetchPosMutation({
                mutation: DISCOUNT,
                variables: details,
                doAction: (response) => {
                    const { posDiscount } = response.data;

                    if (posDiscount.message) {
                        localStorage.setItem('discount_response', posDiscount.message);
                    } else if (posDiscount.success) {
                        localStorage.setItem('discount_response', posDiscount.success);
                    }

                    getCustomerCart();
                },
            }).finally(() => {
                dispatch({
                    type: 'show_cart_loader',
                    payload: false
                });
            });
        }
    };

    const removeDiscount = async (details) => {
        dispatch({
            type: 'show_cart_loader',
            payload: true
        });
        const { data } = await addDiscount({
            variables: {
                discount_value: '0',
                item_id: details.item_id,
            }
        });

        let discountResponse = '';
        if (data.message) {
            discountResponse = data.message
        } else if (data === true) {
            discountResponse = data;
            localStorage.removeItem('discount_response');
        }
        localStorage.setItem('discount_removed_response', discountResponse);
        getCustomerCart();
        dispatch({
            type: 'show_cart_loader',
            payload: false
        });
    };

    const applyGiftCardCode = (details) => {
        if (details.am_gift_card_code) {
            dispatch({
                type: 'show_cart_loader',
                payload: true
            });

            details.cart_id = localStorage.getItem('cart_hash');

            return fetchPosMutation({
                mutation: APPLY_GIFT_CARD,
                variables: {
                    input: details,
                },
                doAction: (response) => {
                    messageAlert.success('Gift Card added successfully!');
                    localStorage.setItem('gift_card_response', '1');
                    getCustomerCart();
                    return response;
                },
            }).finally(() => {
                dispatch({
                    type: 'show_cart_loader',
                    payload: false
                });
            });
        }
    };

    const removeGiftCardCode = (details) => {
        dispatch({
            type: 'show_cart_loader',
            payload: true
        });

        details.cart_id = localStorage.getItem('cart_hash');

        return fetchPosMutation({
            mutation: REMOVE_GIFT_CARD,
            variables: {
                input: details,
            },
            doAction: (response) => {
                messageAlert.success('Gift Card deleted successfully!');
                localStorage.setItem('gift_card_removed_response', response.data);
                getCustomerCart();
                return response;
            },
        }).finally(() => {
            dispatch({
                type: 'show_cart_loader',
                payload: false
            });
        });
    };

    return {
        addProductToCart,
        getCustomerCart,
        removeProductFromCart,
        updateQuoteItemQtyInCart,
        applyCoupon,
        removeCoupon,
        applyDiscount,
        removeDiscount,
        applyGiftCardCode,
        removeGiftCardCode,
        updateShippingInformation,
    };
};

export default useCart;
