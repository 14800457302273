import React, { useState, useEffect, useContext, useRef } from 'react';
import useCart from '../../../talons/useCart';
import { formattedPrice } from '../../../Utils';
import { Store } from '../../../Store';
import StorageForm from '../../Products/StorageForm';
import { __ } from '../../Translations';
import "./cartProduct.css";

const CartProduct = ({ product, onQtyChange, removeItemfromcart }) => {
    const {
        name,
        uid,
        item_id: productId,
        price_incl_tax: priceIncTax,
        qty,
        row_total_incl_tax: rowTotal,
        extension_attributes: { options = [], item_discount: itemDiscount, sku },
    } = product;

    const { dispatch, state: { qtyError } } = useContext(Store);
    const { updateQuoteItemQtyInCart, applyDiscount, removeDiscount} = useCart();
    const [inputQty, setInputQty] = useState(1);
    const [skuForStoragePopup, setSkuForStoragePopup] = useState('');
    const [discount, setDiscount] = useState('');
    const isInputChanged = useRef(true);

    const onChange = (e) => {
        if (+e.target.value + 1 > 999) {
            setInputQty(999);
        } else {
            setInputQty(e.target.value);
        }
    };

    const handleBlur = () => {
        if(inputQty >= 1 && inputQty !== qty) {
            updateQuoteItemQtyInCart({
                cart_id: localStorage.getItem('cart_hash'),
                cart_items: [{cart_item_uid: uid, quantity: inputQty}],
            });
            isInputChanged.current = false;
        } else {
            setInputQty(qty);
        }
    };

    const handleChooseStorage = (e) => {
        e.stopPropagation();
        setSkuForStoragePopup(sku);
    };

    const onDiscountChange = (e) => {
        setDiscount(e.target.value);
    };

    const onDiscountFocus = () => {
        setDiscount('');
    };

    const onDiscountBlur = () => {
        if (discount && discount !== '0' && discount !== itemDiscount) {
            applyDiscount({
				discount_value: discount,
                item_id: productId,
			});
        } else if (discount === itemDiscount) {
            return;
        } else {
            setDiscount('');

            if (itemDiscount) {
                removeDiscount({ item_id: productId });
            }
        }
    }

    useEffect(() => {
        if (isInputChanged.current) {
            setInputQty(qty);
        }

        isInputChanged.current = true;
    }, [qty]);

    useEffect(() => {
        if (qtyError) {
            setInputQty(qty);

            dispatch({
                type: 'qty_error',
                payload: false
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qtyError]);

    useEffect(() => {
        if (itemDiscount) {
            setDiscount(Math.round(itemDiscount * qty * 100) / 100);
        } else {
            setDiscount('');
        }
    }, [itemDiscount, qty, product]);

    return (
        <li className="cart-product-container">
            <div className="cart-product-wrap">
                <div className='d-flex flex-column'>
                    <div className="cart-product-name">
                        {name}
                        <button
                            onClick={handleChooseStorage}
                            title="Show storage location"
                        >
                            <img
                                src="images/storage.png"
                                alt="img" width="20"
                                height="20"
                            />
                        </button>
                    </div>
                    {
                        !!options.length && (
                            <div className='cart-product-options'>
                                {
                                    options.map(({ label, value }, index) => (
                                        <span key={index}>
                                            {label}<span className='cart-product-options-value'>
                                                : {label === 'Price' ? formattedPrice(+value, true) : value}
                                            </span>
                                        </span>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
                <div
                    onClick={(e) => removeItemfromcart(product, e)}
                    className="cart-product-remove"
                >
                    <img src="images/delete.png" alt="remove button" />
                </div>
            </div>
            <div className="cart-product-price">
                <span className="cart-product-price-value">{formattedPrice(priceIncTax)}</span>
                <div className="quanity d-inline-flex">
                    <span className="qty-action remove-qty" onClick={() => onQtyChange('decrease', product)}>-</span>
                    <input
                        className="cart-product-quantity ng-pristine ng-valid ng-touched"
                        id="qty"
                        maxLength={3}
                        value={inputQty}
                        onChange={onChange}
                        onBlur={handleBlur}
                    />
                    <span className="qty-action add-qty" onClick={() => onQtyChange('increase', product)}>+</span>
                </div>
                <span className="cart-product-price-value">{formattedPrice(rowTotal)}</span>
                <div className="cart-product-discount">
                    <input
                        className="ng-pristine ng-valid ng-touched"
                        type="text"
                        id="discount"
                        value={discount}
                        onChange={onDiscountChange}
                        onBlur={onDiscountBlur}
                        onFocus={onDiscountFocus}
                    />
                    <img src="images/discount.png" alt="discount" />
                    <span>
                        <span>{itemDiscount ? `-${Math.round(itemDiscount * qty / rowTotal * 100)}%` : __('Discount')}</span>
                    </span>
                </div>
            </div>
            {
                skuForStoragePopup && (
                    <StorageForm
                        productSku={skuForStoragePopup}
                        closePopup={() => setSkuForStoragePopup('')}
                    />
                )
            }
        </li>
    );
};

export default CartProduct;
