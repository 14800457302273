import React from 'react';
import { useAlert } from "react-alert";
import { __ } from '../Translations';
import "./dailyReport.css";

const DailyReport = () => {
    const messageAlert = useAlert();
    const report = JSON.parse(localStorage.getItem('daily_report'));

    if (report &&  report.error) {
        messageAlert.error(report.error.message);

        return (
            <div className='daily-report-error d-flex flex-column p-3'>
                {report.error.message}
            </div>
        )
    }

    return (
        <div className='daily-report-container d-flex flex-column p-3'>
            <div className='daily-report-title mb-2'>
                {__('Daily cash register')}
            </div>
            {
                report && report.data && report.data.map((
                    { title, totals, payment_method: method},
                ) => (
                    <div className='d-flex justify-content-between flex-column pt-1 pb-1' key={method}>
                        {title}:
                        {
                            totals && totals.length ? (
                                totals.map(({ grand_total: total, currency }) => (
                                    <span className='daily-report-value' key={currency}>
                                        {total.toFixed(2)}
                                        <span>{currency}</span>
                                    </span>
                                ))
                            ) : (
                                <span className='daily-report-value'>0.00<span>CHF</span></span>
                            )
                        }
                    </div>
                ))
            }
        </div>

    );
};

export default DailyReport;
