import React, { useState, useContext } from "react";
import PropTypes from 'prop-types';
import CreateRefund from "./createRefund";
import $ from 'jquery';
import { useRefund } from '../../talons/useCustom'
import { useAlert } from "react-alert";
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import SmartDataTable from '../SmartTable/react-smart-data-table/lib/SmartDataTable';
import OrderViewSkeleton from "./OrderViewSkeleton.js";
import { formattedPrice } from "../../Utils";
import { Store } from '../../Store';
import "./refund.css";

const CREDITMEMO_LIST = gql`
    query agentCreditMemo {
        agentCreditMemo {
            allCreditMemoRecords{
                id
                increment_id
                grand_total
                created_at
            }
        }
    }
`;

const Refund = (props) => {
	const { close, orderId, setIsCanCreditMemo } = props;
    const { dispatch } = useContext(Store);
    const [showLoader, setShowLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isOpenRefundReport, setIsOpenRefundReport] = useState(false);
    const alert = useAlert();
    const { refundOrder, resdata, reserror } = useRefund();
    const loginToken = localStorage.getItem('login_token');

    if (typeof reserror !="undefined") {
        alert.error(reserror.graphQLErrors[0].message);
    }

    if (typeof resdata != "undefined") {
        if(resdata.success){
            if(!showAlert){
                setShowAlert(true);
                alert.success('Credit Memo Created');
            }
        }else{
            alert.error('Something went wrong while creating credit memo');
        }
    }

    const { loading, data, refetch } = useQuery(CREDITMEMO_LIST, {
        fetchPolicy: 'network-only',
        context: {
            headers: {
                'Content-Currency': localStorage.getItem('currency_code'),
                'Seller': loginToken,
            }
        },
    });

    const tableData = [];

	if (typeof data !== 'undefined') {
		data.agentCreditMemo.allCreditMemoRecords.forEach(v => {
			tableData.push({
                created_at: v.created_at,
				id: v.id,
				increment_id: v.increment_id,
				grand_total: formattedPrice(+v.grand_total, true),
			});
		});
	}

    const getRequestData = () => {
        const data = ($('#refund_form').serializeArray().reduce(
            (f,c) => {
                f[c['name']]=(f[c['name']])?[].concat(f[c['name']],c['value']):c['value'];
                return f;
            }, {}));
        let itemData = '';

        Object.keys(data).forEach((key) => {
            if(key.indexOf("item_id_") !== -1){
                const itemid =  key.replace("item_id_", "");
                itemData = itemData+","+itemid+"->"+data["item_id_"+itemid];
            }
        });

        return { data, itemData };
    };

    const checkAdjustmentData = (value) => value ? parseFloat(value) : 0;

    const handleRefund = async () => {
        setShowLoader(true);
        const { data, itemData } = getRequestData();

        await refundOrder({
            order_id: data.order_id,
            itemData,
            adjustment_refund: checkAdjustmentData(data.adjustment_refund),
            adjustment_fee: checkAdjustmentData(data.adjustment_fee),
        });

        refetch();

        dispatch({
            type: 'product_update',
            payload: true
        });

        setShowLoader(false);
        setIsOpenRefundReport(true);
    };

	return (
        <div className="show-refund">
            <div className="refund-wrapper">
                <div className="refund-header d-flex justify-content-between">
                    <h5>Create Refund</h5>
                    <span className="close-icon" onClick={close}>X</span>
                </div>
                <div className="mt-4 refund-inner-wrapper">
                    {
                        orderId && (
                            <div className="view-order-wrapper">
                                <CreateRefund
                                    orderId={orderId}
                                    showLoader={showLoader}
                                    setIsCanCreditMemo={setIsCanCreditMemo}
                                />
                            </div>
                        )
                    }
                    {
                        data && !tableData.length && !orderId && (
                            <div className="order-modal-body">
                                <p>{"No Orders Found"}</p>
                            </div>
                        )
                    }
                    {
                        loading && (
                            <div>
                                <OrderViewSkeleton />
                            </div>
                        )
                    }
                </div>
                {
                    isOpenRefundReport && (
                        <div className="refunds-modal-body">
                            <div className="refund-header d-flex justify-content-between">
                                <h5>Daily refund report</h5>
                                <span className="close-icon" onClick={() => {setIsOpenRefundReport(false); close();}}>X</span>
                            </div>
                            <SmartDataTable
                                data={tableData}
                                name="po-table"
                                className="ui compact selectable table"
                                sortable
                                perPage={10}
                            />
                        </div>
                    )
                }
                <div className="refund-footer text-right">
                    <button className="btn btn-secondary" type="button" onClick={close}>
                        Back to order
                    </button>
                    {
                        data && !!tableData.length && (
                            <button
                                className="print-button ml-3"
                                onClick={() => setIsOpenRefundReport(true)}
                            >
                                Refund report
                            </button>
                        )
                    }
                    {
                        !isOpenRefundReport && (
                            <button
                                className="btn btn-primary ml-3"
                                type="button"
                                onClick={handleRefund}
                            >
                                Refund order
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
	);
}

Refund.propTypes = {
	orderId: PropTypes.number,
	close: PropTypes.func,
    setIsCanCreditMemo: PropTypes.func,
};

export default Refund;
