import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useAlert } from "react-alert";
import CategoryFilter from "../CategoryFilter/CategoryFilter";
import Search from "../Search/Search";
import Skelton from "../Skelton/Skelton";
import Loader from "../Loader/Loader";
import { Store } from '../../Store';
import useCart from '../../talons/useCart'
import { useDailySalesIndicator } from '../../talons/useCustom';
import { Scrollbars } from 'react-custom-scrollbars';
import Price from '../Price';
import StorageForm from './StorageForm';
import { __ } from '../Translations';
import "../Pos/pos.css";

const PRODUCTS = gql`
	query getLatestProduct($category_id: String!, $store_id: String!) {
		latestProduct(category_id: $category_id, store_id: $store_id) {
			data {
				name
				price
				final_price
				sku
				image_url
				stock_qty
			}
		}
	}
`;

const searchQuery = gql`
	query getSearchProduct($search_keyword: String!, $is_barcode: String!, $store_id: String!) {
		getSearchProduct(search_keyword: $search_keyword, is_barcode: $is_barcode, store_id: $store_id) {
			data {
				name
				price
				final_price
				sku
				image_url
				stock_qty
			}
		}
	}
`;

const Products = (props) => {
	const { history, setCustomerData } = props;
	const alert = useAlert();
	const { addProductToCart } = useCart();
	const { state, dispatch } = useContext(Store);
	const [searchValue, setSearchValue] = useState("");
	const [refreshPage, setRefreshPage] = useState(false);
	const [filterValue, setFilterValue] = useState("");
	const [skuForStoragePopup, setSkuForStoragePopup] = useState('');
	const { getDailyData } = useDailySalesIndicator();
	const client = useApolloClient();
	const [searchData, setSearchData] = useState({getSearchProduct: {}, isLoading: false});

	if((state.checkoutSuccess && !refreshPage) || (state.productUpdate && !refreshPage)){
		setRefreshPage(true);
		dispatch({
            type: 'product_update',
            payload: false
        });
	}

	const storeId = localStorage.getItem('store_id') || '';
    const storage = localStorage.getItem('source_code');
	const storeCode = localStorage.getItem('store_code');
	const datavariables = filterValue
		? { category_id: filterValue, store_id: storeId }
		: { category_id: 0, store_id: storeId };

    if (!state.storeMode) {
        state.storeMode = localStorage.getItem('storage_mode');
    }

	const { loading, data, refetch } = useQuery(PRODUCTS, {
		variables: datavariables,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'network-only',
        context: {headers: {
			'Storage': storage,
			'Storage-Mode': state.storeMode,
            'Store': storeCode,
			'Content-Currency': localStorage.getItem('currency_code'),
		}},
	});

	if (state.productUpdate === true || state.checkOutSuccess === true) {

		if (state.productUpdate) {
			dispatch({
				type: 'product_update',
				payload: false,
			});
		}

        if (state.storeMode === 'pos') {
            refetch();
        } else {
            dispatch({
                type: 'store_mode',
                payload: 'pos',
            });
            localStorage.setItem('storage_mode', 'pos');
        }

        getDailyData();
	}

	const filterCalled = (value) => {
		setFilterValue(value);
	};

	const addToCart = (value) => {
		if (!localStorage.getItem('customerId')) {
			alert.error("Please select customer first");
			return false;
		}

		if (state.isShiftOpen) {
			addProductToCart({
				cart_id: localStorage.getItem('cart_hash'),
				cart_items: [{
					data: { sku: value.sku, quantity: 1 },
				}],
			});
		} else {
			alert.error(__('Please open shift first'));
		}
	};

	let agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
	let limit;
	if(agentDetails !== null){
		agentDetails['pos_settings'] = JSON.parse(agentDetails.pos_settings);
		limit = agentDetails['pos_settings'].low_stock_qty;
	}

	const handleChooseStorage = (e, sku) => {
		e.stopPropagation();

		setSkuForStoragePopup(sku);
	};

	useEffect(() => {
		if (state.checkOutSuccess && state.customerList.length) {
			localStorage.removeItem('quote_id');
			setCustomerData();
			dispatch({type: 'currency_code', payload: 'CHF'});
			localStorage.setItem("currency_code", 'CHF');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.checkOutSuccess]);

	useEffect(() => {
		refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.storeMode]);

	useEffect(() => {
		if (
			searchData
			&& searchData.getSearchProduct
			&& searchData.getSearchProduct.data
			&& searchData.getSearchProduct.data.length === 1
			&& searchValue
			&& state.isShiftOpen
			) {
			addProductToCart({
				cart_id: localStorage.getItem('cart_hash'),
				cart_items: [{
					data: { sku: searchData.getSearchProduct.data[0].sku, quantity: 1 },
				}],
			});
			setSearchValue('');
			setSearchData({getSearchProduct: {}, isLoading: false});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchData]);

	useEffect(() => {
		let timer;

		if (searchValue) {
			timer = setTimeout(() => {
				setSearchData({...searchData, isLoading: true});

				client.query({
						query: searchQuery,
						variables: {search_keyword: searchValue, is_barcode: '', store_id: storeId},
						notifyOnNetworkStatusChange: true,
						fetchPolicy: 'network-only',
						context: {headers: {
							'Storage': storage,
							'Storage-Mode': state.storeMode,
							'Store': storeCode,
						}},
					}).then((response) => setSearchData({...response.data, isLoading: false}))
					.catch((err) => alert.error(err));
			}, 1000);
		}

		return () => clearTimeout(timer);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	const renderProducts = (isSearch) => {
		const products = data || searchData ? (isSearch ? searchData.getSearchProduct.data : data.latestProduct.data) : [];

		return (
			<div className="content-wrapper product-tiles-wrapper d-flex flex-wrap">
				{data && products.map((value, index) => {
					var imageUrl = value.image_url;
					if(imageUrl.indexOf("image.jpg") !== -1){
						imageUrl = "images/212X263.png";
					}
					var product_class = "product-box";
					if(value.stock_qty === 0){
						product_class = "product-box out-of-stock";
					}else if(value.stock_qty <= limit ){
						product_class = "product-box low-stock";
					}
					return (
						// eslint-disable-next-line jsx-a11y/anchor-is-valid
						<a onClick={() => addToCart(value)} key={index} className={product_class}>
							<div className="img-wrap">
								<img className="img-fluid" alt={value.name} src={imageUrl} />
							</div>
							<button
								className="select-storage-btn"
								onClick={(e) => handleChooseStorage(e, value.sku)}
								title="Show storage location"
							>
								<img
									src="images/storage.png"
									alt="img" width="40"
									height="40"
								/>
							</button>
							<div className="product-info">
								<p className="product-name mb-1">{value.name}</p>
								<p className="product-price mb-1">
									<Price
										isCurrencyRight
										value={+value.final_price}
									/>
								</p>
								<p className="stock-info instock">
									<span className="stock-name">In Stock:</span>
									<span className="stock-count">{value.stock_qty}</span>
								</p>
							</div>
						</a>
					);
				})}
			</div>
		);
	};

	const showProductData = () => {
		if (loading || searchData.isLoading) {
			return(
				<Scrollbars>
					<Skelton />
				</Scrollbars>
			);
		}
		if (searchValue && searchData && searchData.getSearchProduct) {
			if (searchData && searchData.getSearchProduct.data) {
				return (
					<Scrollbars className="custom-scroll">
						{renderProducts(true)}
					</Scrollbars>
				);
			} else {
				return (
					<div className="noproduct-wrapper d-flex flex-wrap justify-content-center">
						<img className="mb-4" alt="no result found" src="images/search(3).png" width="60" height="60" />
						<p className="no-product">Sorry, we did not find any product.</p>
					</div>
				);
			}
		} else {
			if (data && data.latestProduct.data) {
				return (
					<div className="left-wrapper">
						{state.showLoader && <Loader />}
						<Scrollbars className="custom-scroll">
							{renderProducts()}
						</Scrollbars>
					</div>
				);
			} else {
				return (
					<div className="noproduct-wrapper d-flex flex-wrap justify-content-center">
						<img className="mb-4" alt="no result found" src="images/search(3).png" width="60" height="60" />
						<p className="no-product">Sorry, we did not find any product.</p>
					</div>
				);
			}
		}
	}
	// if (loading) {
	// 	return (
	// 		<div className="left-content-wrapper">
	// 			<Search history={props.history} onChange={setSearchValue} />
	// 			<CategoryFilter history={props.history} onChange={filterCalled} />
	// 			<Scrollbars>
	// 				<Skelton />
	// 			</Scrollbars>
	// 		</div>
	// 	);
	// }
	return (
		<div className="left-section-wrapper">
			<Search history={history} onChange={setSearchValue} searchValue={searchValue} />
			<CategoryFilter history={history} onChange={filterCalled} />
			{showProductData()}
			{
				skuForStoragePopup && (
					<StorageForm
						productSku={skuForStoragePopup}
						closePopup={() => setSkuForStoragePopup('')}
					/>
				)
			}
		</div>
	);
}

Products.propTypes = {
	history: PropTypes.shape({}),
	setCustomerData: PropTypes.func,
};

export default Products;
