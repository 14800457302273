import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup } from "react-bootstrap";
import './orderDatePicker.css';

const OrderDatePicker = ({
    onChange,
    searchData,
    placeholder,
}) => {
    return (
        <div className='d-flex flex-column m-0'>
            <FormGroup className='d-flex m-0 justify-content-between align-items-center'>
                From
                <FormControl
                    className='order-date-picker-input'
                    type="date"
                    placeholder={placeholder}
                    value={searchData['from'] || ''}
                    onChange={(e) => onChange({ ...searchData, from: e.target.value})}
                    size="sm"
                />
            </FormGroup>
            <FormGroup className='d-flex m-0 justify-content-between align-items-center'>
                To
                <FormControl
                    className='order-date-picker-input-to'
                    type="date"
                    placeholder={placeholder}
                    value={searchData['to'] || ''}
                    onChange={(e) => onChange({ ...searchData, to: e.target.value})}
                    size="sm"
                />
            </FormGroup>
        </div>
    )
};

OrderDatePicker.propTypes = {
    onChange: PropTypes.func,
    searchData: PropTypes.shape({}),
    placeholder: PropTypes.string,
};

export default OrderDatePicker;
