import React from 'react';
import PropTypes from 'prop-types';

const MenuPopupWrap = ({children, onClose, title}) => (
    <div className="orders-wrapper">
			<div className="overlay order-details-wrapper" onClick={onClose} />
			<div className="order-modal-header d-flex justify-content-between align-items-center">
				<h1 className="mb-0">{title}</h1>
				<span onClick={onClose}>X</span>
			</div>
			{children}
			<div className="order-modal-footer d-flex justify-content-end align-items-center">
				<button className="close-button" onClick={onClose}>Close</button>
			</div>
		</div>
);

MenuPopupWrap.propTypes = {
    children: PropTypes.node,
	onClose: PropTypes.func,
    title: PropTypes.string,
};

export default MenuPopupWrap;
