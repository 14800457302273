import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { __ } from '../../Translations';
import Loader from '../../Loader/Loader';
import { Store } from '../../../Store';
import './customerForm.css';

const CREATE_CUSTOMER_MUTATION = gql`
    mutation createCustomer($firstname: String!, $lastname: String!, $contact_number: String, $email: String!, $password: String!) {
        createCustomer(
            input: {
                firstname: $firstname
                lastname: $lastname
                contact_number: $contact_number
                email: $email
                password: $password
            }
        ) {
            customer {
                id,
                firstname
            }
        }
    }
`;

const CustomerForm = (props) => {
    const { openPopup, closePopup, text } = props;
    const { dispatch } = useContext(Store);
    const [createCustomer, { loading, error, data }] = useMutation(CREATE_CUSTOMER_MUTATION);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [email, setEmail] = useState("");
    const [requiredFirstNameErrorMessage, setRequiredFirstNameErrorMessage] = useState("");
    const [requiredLastNameErrorMessage, setRequiredLastNameErrorMessage] = useState("");
    const [requiredEmailErrorMessage, setRequiredEmailErrorMessage] = useState("");
    const [validEmailMessage, setValidEmailMessage] = useState("");

    const validateEmail = () => {
        let regularExpForEmail = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
        if (!regularExpForEmail.test(email)) {
            return true;
        }
        return false;
    };

    const addCustomer = () => {
        let emailErrorMessage = '';
        if (email && validateEmail()) {
            emailErrorMessage = __('Please enter a valid email address (Ex: johndoe@domain.com).');
        }
        setRequiredFirstNameErrorMessage(firstname ? '' : __('This is a required field.'));
        setRequiredLastNameErrorMessage(lastname ? '' : __('This is a required field.'));
        setRequiredEmailErrorMessage(email ? '' : __('This is a required field.'));
        setValidEmailMessage(emailErrorMessage);

        if (firstname && lastname && email && !validateEmail()) {
            formSubmit();
        }
    };

    const formSubmit = async () => {
        const store = localStorage.getItem('store_code');
        let password = contactNumber + 'pos@1234';
        let customerEmail = contactNumber + "@" + process.env.REACT_APP_POS_DOMAIN_NAME;
        if (email && validateEmail) {
            customerEmail = email;
        }
        await createCustomer({
            variables: {
                firstname,
                lastname,
                contact_number: contactNumber,
                email: customerEmail,
                password,
            },
            context: {headers: {'Store': store, 'Content-Currency': localStorage.getItem('currency_code')}}
        });
        document.getElementById("add_new_customer_form").reset();
        dispatch({
            type: 'customer_update',
            payload: true
        });
    };

    const setFirstName = (e) => {
        setFirstname(e.target.value);
        setRequiredFirstNameErrorMessage(e.target.value ? '' : __('This is a required field.'));
    };

    const setLastName = (e) => {
        setLastname(e.target.value);
        setRequiredLastNameErrorMessage(e.target.value ? '' : __('This is a required field.'));
    };

    const handleSetContactNumber = (e) => {
        setContactNumber(e.target.value);
    };

    const setCustomerEmail = (e) => {
        setEmail(e.target.value);
        setRequiredEmailErrorMessage(e.target.value ? '' : __('This is a required field.'));
    };

    return (
        <div className='pos-customer-form-popup'>
            <div className="pos-form-inner-wrap">
                {data && data.createCustomer.customer.firstname && (
                    <div className="success-message">
                        <img className="mr-2" src="images/CORRECT.png" alt="correct-img" width="20" height="20" />
                        {data.createCustomer.customer.firstname + " added successfully."}
                    </div>
                )}
                {loading && (
                    <div className="loading">
                        <Loader />
                    </div>
                )}
                {error && error.graphQLErrors.map(({ message }, i) => (
                    <div className="error-message">
                        <img className="mr-2" src="images/warning.png" alt="error-img" width="20" height="20" />
                        <span key={i}>{message}</span>
                    </div>
                ))}
                <div className={"pos-customer-form-inner " + (openPopup ? "open-modal" : "")}>
                    <div className="modal-header pos-modal-header">
                        <h2 className="modal-title">{text}</h2>
                    </div>
                    <div className="modal-body">
                        <form id="add_new_customer_form" className="form-body d-flex justify-content-between flex-wrap">
                            <div className="pos-customer-field mb-3">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="firstname">
                                        {__('First Name')}
                                        <span className="required">*</span>
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="firstname"
                                            placeholder={__('First Name')}
                                            type="text"
                                            onChange={setFirstName}
                                        />
                                    </div>
                                </div>
                                {requiredFirstNameErrorMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{requiredFirstNameErrorMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field mb-3">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="firstname">
                                        {__('Last Name')}
                                        <span className="required">*</span>
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="firstname"
                                            placeholder={__('Last Name')}
                                            type="text"
                                            onChange={setLastName}
                                        />
                                    </div>
                                </div>
                                {requiredLastNameErrorMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{requiredLastNameErrorMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="contactNumber">
                                        {__('Contact Number')}
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="contactNumber"
                                            placeholder={__('Contact Number')}
                                            type="text"
                                            onChange={handleSetContactNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="pos-customer-field">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="email">
                                        {__('Email')}
                                        <span className="required">*</span>
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="email"
                                            placeholder={__('Email')}
                                            type="text"
                                            onChange={setCustomerEmail}
                                        />
                                    </div>
                                </div>
                                {
                                    (requiredEmailErrorMessage || validEmailMessage) && (
                                        <div className="error-message p-1 mb-2 field-error">
                                            {requiredEmailErrorMessage ? requiredEmailErrorMessage : null}
                                            {validEmailMessage ? ` ${validEmailMessage}` : null}
                                        </div>
                                    )
                                }
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer pos-modal-footer pt-0">
                        <button className="add-customer-button" onClick={addCustomer}>{__('Add Customer')}</button>
                        <button className="close-button" onClick={closePopup}>{__('Close')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

CustomerForm.propTypes = {
    openPopup: PropTypes.bool,
    closePopup: PropTypes.func,
    text: PropTypes.string,
};

export default CustomerForm;
