import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Loader from '../../Loader/Loader';
import './storageForm.css';

const STORAGE_LOCATION_QUERY = gql`
    query storageLocation($sku: String!) {
        storageLocation(sku: $sku) {
            variation_id,
            data {
                name
                code
                data {
                    qty
                    location
                }
            }
        }
    }
`;

const StorageForm = (props) => {
    const { productSku, closePopup } = props;
    const storage = localStorage.getItem('source_code');
    const loginToken = localStorage.getItem('login_token');
    const { loading, error, data } = useQuery(STORAGE_LOCATION_QUERY, {
        variables: {
            sku: productSku,
        },
        context: {
            headers: {
                'Storage': storage,
                'Content-Currency': localStorage.getItem('currency_code'),
                'Store': localStorage.getItem('store_code'),
                'Seller': loginToken,
            }
        },
        fetchPolicy: "network-only",
    });

    return (
        <div className="pos-customer-form-popup">
            <div className="pos-form-inner-wrap store-form-inner-wrap">
                {
                    error && error.graphQLErrors.map(({ message }, i) => (
                        <div className="error-message">
                            <img className="mr-2" src="images/warning.png" alt="error-img" width="20" height="20" />
                            <span key={i}>{message}</span>
                        </div>
                    ))
                }
                <div className={"pos-customer-form-inner " + (productSku ? "open-modal" : "")}>
                    <div className="modal-header pos-modal-header">
                        <h2 className="modal-title">Storage locations (Variation: {(data && data.storageLocation) && data.storageLocation.variation_id})</h2>
                        <span className="close-icon modal-title" onClick={closePopup}>X</span>
                    </div>
                    <div className="modal-body">
                        {loading && <div className="loading"><Loader /></div>}
                        {
                            data && data.storageLocation.data
                            ? (
                                data.storageLocation.data.map(({ name, code, data }) => (
                                    <div className='store-form-container' key={code}>
                                        <img
                                            src="images/location.png"
                                            alt="img" width="15"
                                            height="15"
                                        />
                                        <span className='store-form-title'>{name}:</span>
                                        {
                                            data.map(({ qty, location }, index) => (
                                                <span key={index}>
                                                    <span className='store-form-qty'>{qty}</span>({location})
                                                </span>
                                            ))
                                        }
                                    </div>
                                ))
                            )
                            : <div className='store-form-container'>No data available</div>
                        }
                    </div>
                    <div className="modal-footer pos-modal-footer pt-0">
                        <button className="close-button" onClick={closePopup}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

StorageForm.propTypes = {
    productSku: PropTypes.string,
    closePopup: PropTypes.func,
};

export default StorageForm;
