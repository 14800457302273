import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_CREDITMEMO_MUTATION, UPDATEQTY_MUTATION, DAILY_SALES_INDICATOR } from '../queries/useCustomGraphqlschemas';
import { useAlert } from 'react-alert';

export const useRefund = () => {
    const messageAlert = useAlert();
    const storage = localStorage.getItem('source_code');
    const [createCreditMemo, { error, data }] = useMutation(CREATE_CREDITMEMO_MUTATION, {
        context: {
            headers: {
                'Seller': localStorage.getItem('login_token'),
                'Storage': storage,
                'Content-Currency': localStorage.getItem('currency_code'),
                'Store': localStorage.getItem('store_code')
            }
        }
    });

    const refundOrder = async (details) => {
        const response = await createCreditMemo({
            variables: details
        });
        if (response.message) {
            messageAlert.error(response.message);
        }
    };

    return {
        refundOrder,
        resdata: data && data.createCreditMemo,
        reserror: error
    };
};

export const useUpdateQty = () => {
    const messageAlert = useAlert();
    const storage = localStorage.getItem('source_code');
    const [updateProductQty, { error, data }] = useMutation(
        UPDATEQTY_MUTATION, {
            context: {
                headers: {
                    'Seller': localStorage.getItem('login_token'),
                    'Storage': storage,
                    'Content-Currency': localStorage.getItem('currency_code'),
                    'Store': localStorage.getItem('store_code')
                }
            }
        });

    const updateQty = async (details) => {
        const response = await updateProductQty({ variables: details });

        if (response.message) {
            messageAlert.error(response.message);
        }
    };

    return {
        updateQty,
        productData: data && data.updateQty,
        qtyUpdateError: error
    };
};

export const useDailySalesIndicator = () => {
    const storage = localStorage.getItem('source_code');
    const messageAlert = useAlert();

    const { error, data, refetch } = useQuery(DAILY_SALES_INDICATOR, {
        context: {
            headers: {
                'Seller': localStorage.getItem('login_token'),
                'Storage': storage,
                'Content-Currency': localStorage.getItem('currency_code'),
                'Store': localStorage.getItem('store_code')
            }
        },
    });

    const getDailyData = async () => {
        const response = await refetch();

        if (response.message) {
            messageAlert.error(response.message);
        }
    };

    if (error && error.graphQLErrors.filter(({
                                                 message
                                             }) => message === 'The seller is not authorized!').length > 0) {
        localStorage.clear();
        document.location.reload();
    }

    if (data || error) {
        const dataItems = data && data.dailySalesIndicator ? data.dailySalesIndicator.data : null;
        localStorage.setItem('daily_report', JSON.stringify({
            data: dataItems,
            error
        }));
    }

    return {
        getDailyData,
        data: data && data.dailySalesIndicator.data,
        error,
    };
}
