import { gql } from 'apollo-boost';

export const CREATE_INVOICE_AND_SHIPMENT_MUTATION = gql `
    mutation createInvoiceAndShipment($order_id: Int!, $payment_information: String!) {
        createInvoiceAndShipment(order_id: $order_id, payment_information: $payment_information) {
            invoice_success
            shipment_success
            invoice_id
            shipment_id
        }
    }
`;

export const PLACE_ORDER_MUTATION = gql `
    mutation posPlaceOrder($payment_method: String!, $address_information: String, $cash: Float, $rest_cash: Float) {
        posPlaceOrder(payment_method: $payment_method, address_information: $address_information, cash: $cash, rest_cash: $rest_cash) {
            order_id
        }
    }
`;
