import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { ORDERS_QUERY } from '../../queries/salesOrderGraphqlschemas';
import OrderViewSkeleton from './OrderViewSkeleton';
import { orderFormatPrice } from '../../Utils';
import "./OrderView.css";

const OrderView = ({ orderId, setIsCanCreditMemo }) => {
	const datavariables = {
		id: orderId,
	};
	const loginToken = localStorage.getItem('login_token');

	const { data, error } = useQuery(ORDERS_QUERY,
        {
            variables: datavariables,
            context: {
				headers: {
					'Store': localStorage.getItem('store_code'),
					'Content-Currency': localStorage.getItem('currency_code'),
					'Seller': loginToken,
				}
			}
        });

	if (!data || error) {
		return (
			<div>
				<OrderViewSkeleton />
			</div>
		);
	}

	localStorage.setItem('orderData',JSON.stringify(data.salesOrder));

	if(typeof data != 'undefined') {
		setIsCanCreditMemo(data.salesOrder.can_creditmemo);
	}

	return (
		<div className="modal-body order-modal-body">
			<div className="row">
				<div className="col-6 text-right border-right my-1">
					<span className="order-info-label">Order ID : </span>
					<span>{data.salesOrder.increment_id}</span>
				</div>
				<div className="col-6 text-left">
					<span className="order-info-label">Order Date : </span>
					<span>{data.salesOrder.created_at}</span>
				</div>
			</div>
			<div className="row">
				<div className="col-6 text-right border-right my-1 order-info-label">
					<span className="order-info-label">Customer Name :</span>
					<span>{data.salesOrder.customer_name}</span>

				</div>
				<div className="col-6 text-left">
					<span className="order-info-label">Customer Phone :</span>
					<span>{data.salesOrder.telephone}</span>
				</div>
			</div>
			<div className="row">
				<div className="col-6 text-right border-right my-1 order-info-label">
					<span className="order-info-label">Payment Mode : </span>
					<span>{data.salesOrder.payment}</span>
				</div>
				<div className="col-6 text-left">
					<span className="order-info-label">Invoice Number : </span>
					<span>{data.salesOrder.invoice_id}</span>
				</div>
			</div>
			<div className="row">
				<div className="col-6 text-right border-right my-1 order-info-label">
					<span className="order-info-label">Status : </span>
					<span>{data.salesOrder.status}</span>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<h5 className="order-items-title text-left mt-4 mb-0 px-3">Order Items</h5>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12 itm-table">
					<table className="custom-table table order-specific-iems">
						<thead>
							<tr>
								<th scope="col">Name</th>
								<th scope="col">Price</th>
								<th scope="col">Quantity</th>
								<th scope="col">Tax Amount</th>
								<th scope="col">Tax Percent</th>
								<th scope="col">Discount Amount</th>
								<th scope="col">Total</th>
							</tr>
						</thead>
						<tbody>
							{data.salesOrder.items.map((value, index) => {
								return (
									<tr key={index}>
										<td>
											{value.name}
										</td>
										<td>
											{orderFormatPrice(value.price, data.salesOrder.currency_code)}
										</td>
										<td>
											{value.qty_ordered}
										</td>
										<td>
											{orderFormatPrice(value.tax_amount, data.salesOrder.currency_code)}
										</td>
										<td>
											{value.tax_percent + "%"}
										</td>
										<td>
											{orderFormatPrice(value.discount_amount, data.salesOrder.currency_code)}
										</td>
										<td>{orderFormatPrice(value.row_total, data.salesOrder.currency_code)}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="row justify-content-end">
						<div className="col-sm-12 col-md-7 col-lg-4">
							<table className="custom-table table subtotal-table">
								<tbody>
									<tr>
										<th className="sub-heading" scope="row">Sub-Total</th>
										<td>{orderFormatPrice(data.salesOrder.subtotal, data.salesOrder.currency_code)}</td>
									</tr>
									{
										data && data.salesOrder.fee ? (
											<tr>
												<th className="sub-heading" scope="row">Fee</th>
												<td >{orderFormatPrice(data.salesOrder.fee, data.salesOrder.currency_code)}</td>
											</tr>
										) : null
									}
									<tr>
										<th className="sub-heading" scope="row">Tax</th>
										<td>{orderFormatPrice(data.salesOrder.tax, data.salesOrder.currency_code)}</td>
									</tr>
									{
										data && data.salesOrder.discount_amount ? (
											<tr>
												<th className="sub-heading total" scope="row">Discount</th>
												<td className="total">
													{orderFormatPrice(data.salesOrder.discount_amount, data.salesOrder.currency_code)}
												</td>
											</tr>
										) : null
									}
									<tr>
										<th className="sub-heading total" scope="row">Total</th>
										<td className="total">{orderFormatPrice(data.salesOrder.grand_total, data.salesOrder.currency_code)}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

OrderView.propTypes = {
	orderId: PropTypes.string,
	setIsCanCreditMemo: PropTypes.func,
};

export default OrderView;
