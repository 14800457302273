import React from 'react';
import PropTypes from 'prop-types';
import { __ } from '../Translations';
import './confirmPopup.css';

const ConfirmPopup = (props) => {
    const { title = '', description = '', closePopup, onConfirm } = props;

    return (
        <div className="pos-customer-form-popup">
            <div className="confirm-form-inner-wrap store-form-inner-wrap">
                <div className={"pos-customer-form-inner open-modal"}>
                    <div className="modal-header pos-modal-header">
                        <h2 className="modal-title">{title}</h2>
                        <span className="close-icon modal-title" onClick={closePopup}>X</span>
                    </div>
                    <div className="confirm-modal-body">
                        <img src="images/attention.png" alt="attention" />
                        {description}
                    </div>
                    <div className="modal-footer pos-modal-footer pt-0">
                        <button className="btn btn-primary" onClick={onConfirm}>{__('Confirm')}</button>
                        <button className="btn btn-secondary" onClick={closePopup}>{__('Cancel')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

ConfirmPopup.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    closePopup: PropTypes.func,
    onConfirm: PropTypes.func,
};

export default ConfirmPopup;
