import { gql } from 'apollo-boost';

export const ORDERS_QUERY = gql`
    query getAllOrders(
            $store_id: Int!,
            $increment_id: String,
            $customer_name: String,
            $customer_email: String,
            $from: String,
            $to: String
        ) {
        allOrders(
            store_id: $store_id,
            increment_id: $increment_id,
            customer_name: $customer_name,
            customer_email: $customer_email,
            from: $from,
            to: $to
        ) {
            records {
                id
                increment_id
                customer_name
                customer_email
                grand_total
                created_at
                status
            }
        }
    }
`;

export const AGENT_ORDERS_QUERY = gql`
    query getAgentOrders(
            $created_at: String,
            $increment_id: String,
            $customer_name: String,
            $customer_email: String,
            $from: String,
            $to: String
        ) {
        agentOrders(
            created_at: $created_at,
            increment_id: $increment_id,
            customer_name: $customer_name,
            customer_email: $customer_email,
            from: $from,
            to: $to
        ) {
            records {
                id
                increment_id
                customer_name
                customer_email
                grand_total
                created_at
                status
            }
        }
    }
`;