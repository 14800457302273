import React, { useContext, useState, useEffect } from 'react';
import { Form, Dropdown, DropdownButton } from 'react-bootstrap';
import { usePosApolloApi } from '../../../posApolloAPI';
import useCart from '../../../talons/useCart';
import { CURRENCY } from '../../../queries/useCustomGraphqlschemas';
import { Store } from '../../../Store';
import './currency.css';

const Currency = () => {
    const { state: { currencyCode, storeMode, isShiftOpen, checkOutSuccess }, dispatch } = useContext(Store);
    const { getCustomerCart } = useCart();
    const { fetchPosQuery } = usePosApolloApi();
    const [data, setData] = useState({});

    const { available_currency_codes: availableCurrencyCodes = [] } = data;

    const doAction = (response) => {
        setData(response.data.currency);
        dispatch({type: 'exchange_rates', payload: response.data.currency.exchange_rates});
        dispatch({type: 'available_currency_codes', payload: response.data.currency.available_currency_codes});
    };

    const getCurrency = () => (
        fetchPosQuery({
            query: CURRENCY,
            doAction,
        })
    );

    const handleClick = (value) => {
        dispatch({type: 'currency_code', payload: value});
        localStorage.setItem('currency_code', value);
    };

    useEffect(() => {
        getCurrency();
        handleClick(localStorage.getItem('currency_code'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isShiftOpen && localStorage.getItem('customerToken') && !checkOutSuccess) {
            getCustomerCart();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currencyCode]);

    return (
        <div className='currency-container'>
            <Form.Group>
                <DropdownButton
                    title={currencyCode}
                    className="checkout-address-select"
                    variant="custom"
                >
                    {
                        storeMode && availableCurrencyCodes.map((code) => (
                            <Dropdown.Item
                                key={code}
                                onClick={() => handleClick(code)}
                            >
                                {code}
                            </Dropdown.Item>
                        ))
                    }
                </DropdownButton>
            </Form.Group>
        </div>
    );
};

export default Currency;