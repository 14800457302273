import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import useRewardPoints from '../../talons/useRewardPoints';
import './rewardPoints.css';

const REWARD_POINTS_STEP = 50;

const RewardPoints = (props) => {
    const { cartDetails, isLoading } = props;
    const messageAlert = useAlert();
    const [isOpen, setIsOpen] = useState(false);
    const error = localStorage.getItem('reward_points_error');
    const [points, setPoints] = useState(0);
    const [isPointsUsed, setIsPointsUsed] = useState(false);
    const {
        rewardPoints: { data },
        getRewardPoints,
        applyRewardPoints,
        removeRewardPoints,
    } = useRewardPoints();
    const { points: rewardPoints = 0, amount } = data;
    const rate = amount && amount.value ? Math.round(rewardPoints / amount) : 0;
    const maxPointsAvailableToSpent = cartDetails && rewardPoints > cartDetails.base_grand_total * rate
        ? cartDetails.base_grand_total * rate : rewardPoints;

    useEffect(() => {
        getRewardPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderToggle = (toggle, src) => (
        <span
            className="add-coupon-img"
            onClick={() => setIsOpen(toggle)}
            role="presentation"
        >
            <img
                className="plus-icon"
                src={src}
                alt="img" width="20"
                height="20"
            />
        </span>
    );

    const appliedRewardPoints = cartDetails && cartDetails.extension_attributes.applied_reward_points
        ? +cartDetails.extension_attributes.applied_reward_points : 0;

    useEffect(() => {
        if (error) {
            setPoints(0);
            setIsPointsUsed(false);
            localStorage.removeItem('reward_points_error');
        }
    }, [error]);

    useEffect(() => {
        if (cartDetails && appliedRewardPoints) {
            setPoints(appliedRewardPoints);
            setIsPointsUsed(true);
        } else {
            setPoints(0);
            setIsPointsUsed(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedRewardPoints]);

    useEffect(() => {
        if (cartDetails.items.length && !isPointsUsed && !isLoading && !appliedRewardPoints) {
            setPoints(maxPointsAvailableToSpent);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartDetails, cartDetails.items.length, isPointsUsed, maxPointsAvailableToSpent]);

    const handleApplyRewardPoints = (e) => {
        if (points && cartDetails && cartDetails.items.length) {
            if (e.target.checked && points < REWARD_POINTS_STEP) {
                setIsPointsUsed(true);
                applyRewardPoints(Math.floor(points / REWARD_POINTS_STEP) * REWARD_POINTS_STEP);
            } else if (e.target.checked && points >= REWARD_POINTS_STEP) {
                setIsPointsUsed(true);
                applyRewardPoints(Math.floor(points / REWARD_POINTS_STEP) * REWARD_POINTS_STEP);
            } else {
                setIsPointsUsed(false);
                removeRewardPoints();
            }
        } else {
            messageAlert.error('You have no points or something wrong. Please, reload the page');
        }

        return false;
    };

    const handleInputOnChange = (e) => {
        const cartMaxTotalPoints = cartDetails.base_grand_total * rate;

        if (rewardPoints && cartDetails.items.length) {
            if (e.target.value <= maxPointsAvailableToSpent) {
                setPoints(e.target.value);
            } else if (e.target.value >= rewardPoints) {
                messageAlert.error('Not enough points for the current value.');
            } else if (e.target.value >= cartMaxTotalPoints) {
                messageAlert.error("You can't use more points then: " + cartMaxTotalPoints);
            }
        }

        return false;
    };

    const inputValidation = (e) => {
        if (['-', '+', 'e', 'E'].includes(e.key)) {
          e.preventDefault();
        }
    };

    return (
        <div className={isOpen ? "reward-container reward-active" : "reward-container"}>
            <div className="reward-title">
                <span className="manage-font">Reward Points</span>
                {isOpen ? renderToggle(false, "images/minus.png") : renderToggle(true, "images/plus.png")}
            </div>
            <div className="reward-wrapper">
                <div className="reward-information">
                    <span className="reward-points">
                        Points available:
                        <span> {rewardPoints}</span>
                    </span>
                    <span className="reward-points">
                        {localStorage.getItem("currency_code")}:
                        <span>  {amount &&  amount.value ? amount.value.toFixed(2) : 0}</span>
                    </span>
                </div>
                <div className="reward-apply">
                    <input
                        className="form-control"
                        name="reward-points"
                        placeholder="Enter points"
                        type="number"
                        step={`${REWARD_POINTS_STEP}`}
                        disabled={isPointsUsed}
                        onChange={handleInputOnChange}
                        onKeyDown={inputValidation}
                        value={points ? points : ''}
                    />
                    <div className="barcode-checkbox reward-checkbox">
                        <input id="rewardcheck" type="checkbox"
                                aria-label="Checkbox"
                                onChange={handleApplyRewardPoints}
                                checked={isPointsUsed}
                        />
                        <label htmlFor="rewardcheck" className="apply-check-text">use/not use</label>
                    </div>
                </div>
            </div>
        </div>
    );
};

RewardPoints.propTypes = {
    cartDetails: PropTypes.shape({}),
    isLoading: PropTypes.bool,
};

export default RewardPoints;
