/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import CustomerSearch from '../CustomerSearch';
import AddCustomer from '../AddCustomer';
import AddProduct from '../AddProduct';
import DiscountBlock from '../DiscountBlock';
import { Store } from '../../Store';
import useCart from '../../talons/useCart';
import useCheckout from '../../talons/useCheckout';
import useRewardPoints from '../../talons/useRewardPoints';
import useAuthorization from '../../talons/useAuthorization';
import RewardPoints from '../RewardPoints';
import CartProduct from './CartProduct';
import { formattedPrice } from '../../Utils';
import ModeSwitcher from '../ModeSwitcher';
import CheckoutAddressForm from '../CheckoutAddressForm';
import Loader from '../Loader/Loader';
import { __ } from '../Translations';
import useDownloadPDF from '../DownloadPDF';
import Currency from './Currency';
import './cart.css';

const DEFAULT_MODE = 'pos';
const EMPLOYEE_MODE = 'employee';

const Cart = (props) => {
	const {
		history,
		setCustomerData,
		showLoader,
		customer,
		setCustomer,
		customerData,
	} = props;
	const { state } = useContext(Store);
	const messageAlert = useAlert();
	const { removeRewardPoints } = useRewardPoints();
	const { handleLogOut } = useAuthorization();
	const [activeToggle, setActiveToggle] = useState('true');
	const [tenderedAmt, setTenderedAmt] = useState('');
	const [changeAmt, setChangeAmt] = useState('');
	const [paymentMode, setPaymentMode] = useState('cash');
	const [transactionId] = useState('');
	const [serviceName] = useState('');
	const [showPaymentOption, setShowPaymentOption] = useState(false);
	const [couponCode, setCouponCode] = useState(false);
	const [discountValue, setDiscount] = useState(false);
	const [giftCardCodes, setGiftCardCodes] = useState('');
	const [deliveryAddress, setDeliveryAddress] = useState({
        full_name: '',
        country_id: 'CH',
        city: '',
        street: '',
        postcode: '',
        telephone: '',
	});
	const [errors, setErrors] = useState({});
	const { startLoadAction, RenderPDFModal } = useDownloadPDF();
	const {
		removeProductFromCart,
		updateQuoteItemQtyInCart,
		applyCoupon,
		removeCoupon,
        applyDiscount,
        removeDiscount,
		applyGiftCardCode,
		removeGiftCardCode,
		// updateShippingInformation,
	} = useCart();
	const { checkout } = useCheckout();
	const quoteId = localStorage.getItem('quote_id');
	const customerToken = localStorage.getItem('customerToken');
	const currencySymbol = localStorage.getItem('currency_code');
	const cartId = localStorage.getItem('cart_hash');
	let cartDetails = "";

	if (quoteId && customerToken) {
		cartDetails = JSON.parse(localStorage.getItem('cartDetails'));
	}

	let agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
	const countries = JSON.parse(localStorage.getItem('countries'));

	const cartContainer = useRef();
	const checkoutContainer = useRef();
	const [calcHeight, setCalcHeight] = useState('auto');

	const shipmentCompareWithAddress = (address) => {
		if (countries.filter(({ value }) => value === address.country_id).length) {
			setDeliveryAddress(address);
		} else {
			setDeliveryAddress({
				full_name: address.full_name ? address.full_name : '',
				country_id: 'CH',
				city: '',
				street: '',
				postcode: '',
				telephone: address.telephone ? address.telephone : '',
			});
		}
	};

	useEffect(() => {
		if (!checkoutContainer.current) return;
		const resizeObserver = new ResizeObserver((e) => {
			setCalcHeight(cartContainer.current.offsetHeight - 210 - e[0].contentRect.height);
		});

		resizeObserver.observe(checkoutContainer.current);

		return () => resizeObserver.disconnect();
	 }, [checkoutContainer, state.storeMode]);

	useEffect(() => {
		if (state.storeMode === 'customer' && customerData && customerData.customerToken.shipping) {
			shipmentCompareWithAddress(customerData.customerToken.shipping);
		} else if (state.storeMode === 'store' && customerData && customerData.customerToken.billing) {
			shipmentCompareWithAddress(customerData.customerToken.billing);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerData, state.storeMode]);

	const discountCheck = () => {
		if (cartDetails && cartDetails.coupon_code && cartDetails.extension_attributes.applied_reward_points) {
			return removeCoupon({ cart_id: cartId }, removeRewardPoints);
		}

		if (cartDetails && cartDetails.coupon_code) {
			return removeCoupon({ cart_id: cartId });
		}

		if (cartDetails && cartDetails.extension_attributes.applied_reward_points) {
			return removeRewardPoints();
		}

		return false;
	};

	const isRendered = useRef(false);

	useEffect(() => {
		const cartDetails = JSON.parse(localStorage.getItem('cartDetails'));

		if(isRendered.current && cartDetails && cartDetails.items.length) {
			const [{ uid, qty }] = cartDetails.items;
			updateQuoteItemQtyInCart({
                cart_id: localStorage.getItem('cart_hash'),
                cart_items: [{cart_item_uid: uid, quantity: qty}],
            }, () => state.storeMode === EMPLOYEE_MODE ? discountCheck() : false)
				/*.then(() => updateShippingInformation())*/;
		}

		isRendered.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.storeMode]);

	if (agentDetails != null) {
		agentDetails['pos_settings'] = JSON.parse(agentDetails.pos_settings);
	} else {
		return <Redirect to='/' />
	}

	let cucode = false;

	if (cartDetails && cartDetails.coupon_code) {
		cucode = cartDetails.coupon_code;
		if (couponCode !== cucode) {
			setCouponCode(cucode);
		}
	}

    let disValue = false;
	if (cartDetails && cartDetails.extension_attributes.pos_discount) {
        disValue = cartDetails.extension_attributes.pos_discount;
		if (discountValue !== disValue) {
            setDiscount(disValue);
        }
	}

	const removeItemfromcart = (item) => {
		removeProductFromCart({
			cart_id: cartId,
			cart_item_uid: item.uid,
		});
	};

	const handleClick = (type, item) => {
		let qty;
		if (item.item_id) {
			let quantity = item.qty;
			if (type === 'decrease') {
				if (quantity - 1 < 1) {
					qty = 1;
				} else {
					qty = quantity - 1;
				}

				if (quantity > 1) {
					updateQuoteItemQtyInCart({
						cart_id: localStorage.getItem('cart_hash'),
						cart_items: [{cart_item_uid: item.uid, quantity: qty}],
					});
				}
			} else {
				let quantity = item.qty;
				if (quantity + 1 > 999) {
					qty = 999;
				} else {
					qty = quantity + 1;
				}
				updateQuoteItemQtyInCart({
					cart_id: localStorage.getItem('cart_hash'),
					cart_items: [{cart_item_uid: item.uid, quantity: qty}],
				});
			}
		}
	};

	const applyCouponCode = () => {
		if (couponCode) {
			applyCoupon({ cart_id: cartId, coupon_code: couponCode });
		}
	};

    const applyManualDiscount = () => {
        if (discountValue) {
            applyDiscount({
				discount_value: discountValue,
			});
        }
    };

	const applyGiftCardDiscount = () => {
        if (giftCardCodes) {
            applyGiftCardCode({ 'am_gift_card_code': giftCardCodes });
        }
    };

	const placeOrder = () => {
		if (cartDetails) {
			if (!(cartDetails.items && cartDetails.items.length > 0)) {
				messageAlert.show('There is no item(s) in cart.');
			}
			if (!(agentDetails.cashondelivery || (agentDetails.paybycard && agentDetails.paybycard_services))) {
				messageAlert.show('There is no payment option available, kindly discuss with owner.');
			}
			if (cartDetails.items && cartDetails.items.length > 0 && (agentDetails.cashondelivery || (agentDetails.paybycard && agentDetails.paybycard_services))) {
				setShowPaymentOption(true);
			}
			if(!agentDetails.cashondelivery) {
				setPaymentMode('card');
			}
			if (tenderedAmt) {
				const eventStatic = {target: {value:
					tenderedAmt > cartDetails.base_grand_total ? tenderedAmt : '',
				}};
				setTenderedAmount(eventStatic);
				if (tenderedAmt < cartDetails.base_grand_total) {
					setChangeAmt('');
				}
			}
		} else {
			messageAlert.show('There is no item(s) in cart.');
		}
	};

	const closePaymentOptionPopup = () => {
		setTenderedAmt('');
		setChangeAmt('');
		setShowPaymentOption(false);
	};

	const validationErrors = {};

	const applyCheckout = () => {
		if (state.storeMode === DEFAULT_MODE || state.storeMode === EMPLOYEE_MODE) {
			checkout('', +tenderedAmt, +changeAmt);
			return null;
		} else {
			if (!Object.keys(validationErrors).length) {
				checkout(JSON.stringify(deliveryAddress), +tenderedAmt, +changeAmt);
				setDeliveryAddress({
					full_name: '',
					country_id: 'CH',
					city: '',
					street: '',
					postcode: '',
					telephone: '',
				});
			} else {
				messageAlert.show('Please enter all required data!');
			}
			return null;
		}
	};

	const confirmCheckout = () => {
		Object.keys(deliveryAddress).forEach((key) => {
			if (!deliveryAddress[key]) {
				validationErrors[key] = true;
			}
		});

		setErrors(validationErrors);

		if (paymentMode === 'cash') {
			if (tenderedAmt && tenderedAmt >= cartDetails.base_grand_total) {
				localStorage.setItem('paymentInformation', JSON.stringify(
					{
						'payment_mode': agentDetails.cashondelivery_title,
						'cart_total': cartDetails.base_grand_total,
						'tendered_amount': tenderedAmt,
						'change_amount': changeAmt,
					}
				));
				localStorage.setItem('payment_mode', 'cash');
				applyCheckout();
				$('.pos-payment-option-container').find('input:text').val('');
			} else {
				messageAlert.show('Tendered Amount is less than Cart Total.');
			}
		} else if (paymentMode === 'card') {
			localStorage.setItem('paymentInformation', JSON.stringify(
				{
					'payment_mode': agentDetails.paybycard_title,
					'cart_total': cartDetails.base_grand_total,
					'service_name': serviceName,
					'transaction_id': transactionId
				}
			));
			localStorage.setItem('payment_mode', 'card');
			applyCheckout();
		}
	};

	const setTenderedAmount = (e) => {
		let tenderedAmount = e.target.value;
		let base_grand_total = cartDetails.base_grand_total;
		const numberRE = /^[0-9]*\.?[0-9]{0,2}$/;
		if (e.target.value && !numberRE.test(e.target.value)) {
			messageAlert.error('Please enter only number.');
		} else {
			setTenderedAmt(tenderedAmount);
			if (tenderedAmount >= base_grand_total) {
				const { rate } = state.exchangeRates.filter(
					(rate) => rate.currency_to === state.currencyCode)[0] || {rate: 1};
				const valueChange = (tenderedAmount - base_grand_total) * rate;
				setChangeAmt(Math.round(valueChange / 0.05) * 0.05);
			}
		}
	};

	if(couponCode === false){
		localStorage.removeItem('coupon_response');
	}

	const discountDataResponse = (localStorageDiscountResponce, statementCheck, value) => {
		let discountResponse = localStorage.getItem(localStorageDiscountResponce);

		if (discountResponse) {
			if (statementCheck) {
				discountResponse = '';
			}

			if(!value) {
				localStorage.removeItem(localStorageDiscountResponce);
				discountResponse = '';
			}
		}

		return discountResponse;
	};

	const openSubMenu = () => {
		setActiveToggle(!activeToggle);
	};

	if (localStorage.getItem('placedOrderId')) {
		localStorage.removeItem('placedOrderId');
		// window.open('/inoviceSlip', '_blank');
		startLoadAction();
	}

	const renderFee = () => {
		if (!cartDetails || (cartDetails && !cartDetails.items.length)) {
			return null;
		}

		return cartDetails.total_segments.map(({ code, title, value }) => {
			if (value > 0 && code === 'fee') {
				return (
					<li key={code} className="list-items subtotal d-flex justify-content-between align-items-center">
						<span className="manage-font">{title || 'Fee'}</span>
						<span className="cart-subtotal">{formattedPrice(value)}</span>
					</li>
				);
			}

			return null;
		});
	};

	let agentName = localStorage.getItem('agentName');

	if (state.checkOutSuccess && showPaymentOption) {
		setTenderedAmt('');
		setChangeAmt('');
		setShowPaymentOption(false);
  	}

	const renderShiftProtectBlock = () => (
		<div className="shift-block-protect">
			{__('There is no opening shift')}
		</div>
	);

	return (
		<div className={state.storeMode !== DEFAULT_MODE ? 'cart-wrapper-right-mode position-relative' : 'cart-wrapper-right position-relative'} ref={cartContainer}>
			<RenderPDFModal />
			{state.showCartLoader && <Loader isAbsolute />}
			{state.isShiftOpen ? null : renderShiftProtectBlock()}
			<div className="unsticky-wrapper">
				<div className="right-cart-section">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-4">
								<a className="user-profile" href="#" onClick={openSubMenu}>
									<span><img className="mr-1" alt="images" src="images/user(1).png" width="20" height="20" /></span>
									<span className="username">{agentName}</span>
									<img className="dropdown-icon" src="images/caret-down.png" alt="img" />

								</a>
							</div>
                            <div className="col-4 p-0">
                                <ModeSwitcher />
                            </div>
							<div className="col-2 p-0 pl-1 pr-1">
								<span className="position-relative">
									<img className="Cart-icon mr-1" src="images/shopping-cart.png" alt="cart-img" width="20" height="20" />
									{cartDetails && cartDetails.items_qty > 0 && (
										<span className="cart-value">{cartDetails.items_qty}</span>
									)}
								</span>
								<span className="cart-text">Cart</span>
							</div>
							<div className="col-2 p-1">
								<Currency />
							</div>
						</div>
					</div>
					<div className={activeToggle ? 'user-submenu' : 'user-submenu opensubmenu'}>
						<a className="logout-btn p-1" onClick={() => handleLogOut(history)} href="/#">
							<span><img width="20" height="20" alt="images" src="images/logout.png" className="d-inline-block mr-1 logout-icon" /></span>
							<span className="d-inline-block">{__('Log out')}</span>
						</a>
					</div>
				</div>
				<div className="search-customer">
					<CustomerSearch
						placeholder="Search Customer"
						noItemFound="No customer found"
						setCustomerData={setCustomerData}
						showLoader={showLoader}
						customer={customer}
						setCustomer={setCustomer}
					/>
				</div>
				<div className="add-more">
					<div className="row">
						<div className="col-lg-6 col-md-12 add-customer-sction">
							<AddCustomer />
						</div>
						<div className="col-lg-6 col-md-12 add-custome-product">
							<AddProduct />
						</div>
					</div>
				</div>
				<div className="tab-carts text-left">
					<p className="my-cart-title">{__('Shopping Cart')}</p>
				</div>
				<div className="product-table-wrapper">
					{cartDetails && (typeof cartDetails.items != 'undefined') && (
							<div
								className="product-table-inner content"
								id="content-2"
								style={{ height: calcHeight }}
							>
								{cartDetails && (cartDetails.items.length > 0) && (
									<ul className="pl-0 mb-0">
										{cartDetails.items && cartDetails.items.map((item, index) => {
											return (
												<CartProduct
													key={index}
													product={item}
													onQtyChange={handleClick}
													removeItemfromcart={removeItemfromcart}
												/>
											);
										})}
									</ul>
								)}
							</div>
						)}
				</div>
			</div>
			<div className="billing-wrapper" ref={checkoutContainer}>
				{showPaymentOption && cartDetails && (
					<div className="pos-customer-form-popup">
						<div className="pos-form-inner-wrap">
							<div className={"pos-customer-form-inner " + (showPaymentOption ? "open-modal" : "")}>
								<div className="modal-header pos-modal-header">
									<h2 className="modal-title">{"Checkout"}</h2>
								</div>
								<div className="modal-body">
									{
										(state.storeMode !== DEFAULT_MODE && state.storeMode !== EMPLOYEE_MODE) && (
											<CheckoutAddressForm
												deliveryAddress={deliveryAddress}
												setDeliveryAddress={setDeliveryAddress}
												validationErrors={validationErrors}
												errors={errors}
												countries={countries}
											/>
										)
									}
									<div className="pos-payment-option-container text-left">
										<div className="pos-row mb-4">
											<label className="title-payment-mode mb-0">{"Select Payment Mode : "}</label>
											<div className="field-wrapper payment-mehtod-type d-inline-block">
												{agentDetails.cashondelivery && (
													<button className={"payment-method-button mr-2 "+((paymentMode === "cash") ? "selected" : "")}
															name="pay_by_cash"
															onClick={() => {setPaymentMode("cash")}}>
														{agentDetails.cashondelivery_title}
													</button>
												)}
												{agentDetails.paybycard && (
													<button className={"payment-method-button "+((paymentMode === "card") ? "selected" : "")} name="pay_by_card" onClick={() => {setPaymentMode("card")}}>
														{agentDetails.paybycard_title}
													</button>
												)}
											</div>
										</div>
										<div className="pos-row d-flex align-items-center">
											<label className="mb-0">{"Cart Total"}</label>
											<div className="field-wrapper amount-wrapper">
												<div className="field-with-currency">
												<span className="currency-symbol">{currencySymbol}</span>
													<input name="cart_total" disabled="disabled" value={cartDetails.base_grand_total.toFixed(2)}/>
												</div>
											</div>
										</div>
										{(paymentMode === "cash") && (
											<div className="pos-row d-flex align-items-center">
												<label className="mb-0">{"Tendered Amount"}</label>
												<div className="field-wrapper amount-wrapper">
													<div className="field-with-currency">
														<span className="currency-symbol">{currencySymbol}</span>
														<input name="tendered_amount" onChange={(e) => setTenderedAmount(e)} value={tenderedAmt}/>
													</div>
												</div>
											</div>
										)}
										{(paymentMode === "cash") && (
											<div className="pos-row d-flex align-items-center">
												<label className="mb-0">{"Change Amount"}</label>
												<div className="field-wrapper amount-wrapper">
													<div className="field-with-currency">
														<span className="currency-symbol">CHF</span>
														<input name="change_amount" disabled="disabled" value={changeAmt ? changeAmt.toFixed(2) : ''}/>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="modal-footer pos-modal-footer pt-0">
									<button className="add-customer-button" onClick={confirmCheckout}>Confirm Checkout</button>
									<button className="close-button" onClick={closePaymentOptionPopup}>Cancel</button>
								</div>
							</div>
						</div>
					</div>
				)}
				<ul className="list-group bottom-sticky-cart">
					<li className="list-items subtotal d-flex justify-content-between align-items-center">
						<span className="manage-font">{agentDetails.pos_settings.subtotal_label}</span>
						{cartDetails && (cartDetails.subtotal > 0) && (
							<span className="cart-subtotal">{formattedPrice(cartDetails.subtotal)}</span>
						)}
					</li>
					{renderFee()}
					<li className="list-items tax d-flex justify-content-between align-items-center">
						<span className="tax-input">{agentDetails.pos_settings.tax_label}</span>
						{cartDetails && (cartDetails.tax_amount > 0) && (
							<span className="cart-tax-amount">{formattedPrice(cartDetails.tax_amount)}</span>
						)}
					</li>
					<DiscountBlock
						discount={cartDetails && cartDetails.coupon_code ? cartDetails.coupon_code : null}
						discountRemoveResponse="coupon_removed_response"
						removeDiscountFromCart={() => removeCoupon({ cart_id: cartId })}
						applyDiscount={applyCouponCode}
						setDiscountCode={setCouponCode}
						discountResponse={discountDataResponse(
							'coupon_response',
							(cartDetails && cartDetails.coupon_code),
							couponCode
						)}
						discountLabel="Apply Coupon Code"
						placeholder="Enter coupon code"
						key="coupon_response"
					/>
					<DiscountBlock
						discount={cartDetails && cartDetails.coupon_code ? cartDetails.coupon_code : null}
						discountRemoveResponse="gift_card_removed_response"
						removeDiscountFromCart={removeGiftCardCode}
						applyDiscount={applyGiftCardDiscount}
						discountCode={giftCardCodes}
						setDiscountCode={setGiftCardCodes}
						discountResponse={discountDataResponse(
							'gift_card_response',
							(cartDetails && cartDetails.extension_attributes.applied_gift_cards && cartDetails.extension_attributes.applied_gift_cards.length),
							giftCardCodes
						)}
						discountLabel="Apply Gift Card Code"
						placeholder="Enter Gift Card Code"
						isGiftCard
						key="gift_card_response"
					/>
					<DiscountBlock
						discount={
							cartDetails && cartDetails.extension_attributes.pos_discount
								? formattedPrice(cartDetails.extension_attributes.pos_discount)
								: null
						}
						discountRemoveResponse="discount_removed_response"
						removeDiscountFromCart={removeDiscount}
						applyDiscount={applyManualDiscount}
						setDiscountCode={setDiscount}
						discountResponse={
							discountDataResponse(
								'discount_response',
								(cartDetails && cartDetails.extension_attributes.pos_discount),
								discountValue
							)
						}
						discountLabel="Apply Discount"
						placeholder="Enter discount"
						key="discount_response"
					/>
					{
						customerToken && cartDetails && !!cartDetails.items.length && (
							<li className="d-flex list-items">
								<RewardPoints cartDetails={cartDetails} isLoading={state.showCartLoader} />
							</li>
						)
					}
					<li className="list-items discount d-flex justify-content-between align-items-center">
						<p className="mb-0 w-100 d-flex justify-content-between">
							<span className="manage-font">{agentDetails.pos_settings.discount_label}</span>
							{cartDetails && (Math.abs(cartDetails.discount_amount) > 0) && (
								<span className="cart-tax-amount">- {formattedPrice(Math.abs(cartDetails.discount_amount))}</span>
							)}
						</p>
					</li>
					<li className="py-0 pr-0 pl-2 checkout blue-bg d-flex justify-content-between align-items-center">
						<span className="checkout-amt">
							<span className="total-text">{agentDetails.pos_settings.grand_total_label}: </span>
							{cartDetails && <span className="cart-grand-total">{formattedPrice(cartDetails.base_grand_total)}</span>}
						</span>
						<button className="checkout-btn position-relative" onClick={placeOrder}>
							<span className="checkout-btn-text">Checkout</span>
						</button>
					</li>
				</ul>
			</div>
		</div>
	);
}

Cart.propTypes = {
	history: PropTypes.shape({}),
	setCustomerData: PropTypes.func,
	showLoader: PropTypes.bool,
	customer: PropTypes.string,
	setCustomer: PropTypes.func,
	customerData: PropTypes.shape({}),
};

export default Cart;
