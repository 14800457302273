import { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { usePosApolloApi } from '../posApolloAPI';
import { CREATE_INVOICE_AND_SHIPMENT_MUTATION, PLACE_ORDER_MUTATION } from '../queries/checkoutSchemas';
import { Store } from '../Store';

const useCheckout = () => {
    const { dispatch } = useContext(Store);
    const storage = localStorage.getItem('source_code');
    const agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
    const messageAlert = useAlert();
    const [orderResponse, setOrderResponse] = useState({});
    const { fetchPosMutation } = usePosApolloApi();

    const createInvoiceAndShipment = () => {
        return fetchPosMutation({
            mutation: CREATE_INVOICE_AND_SHIPMENT_MUTATION,
            variables: {
                order_id: orderResponse.posPlaceOrder.order_id,
                payment_information: localStorage.getItem('paymentInformation'),
                source_code: storage
            },
            notifyOnNetworkStatusChange: true,
            doAction: (response) => {
                dispatch({
                    type: 'checkout_success',
                    payload: true
                });
                return response;
            },
        }).finally(() => {
            dispatch({
                type: 'show_loader',
                payload: false
            });
        });
    };

    useEffect(() => {
        if (orderResponse && orderResponse.posPlaceOrder && orderResponse.posPlaceOrder.order_id) {
            createInvoiceAndShipment();

            localStorage.setItem('placedOrderId', orderResponse.posPlaceOrder.order_id);
            localStorage.setItem('orderIdForInvoice', orderResponse.posPlaceOrder.order_id);
            localStorage.removeItem('cartDetails');
            localStorage.removeItem('customerId');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerToken');
            localStorage.removeItem('quote_id');
            localStorage.removeItem('payment_mode');
            localStorage.removeItem('paymentInformation');
            messageAlert.success('Order placed Successfully.');

            dispatch({
                type: 'cart_items',
                payload: 'refresh_cart'
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderResponse]);

    const checkout = (placeOrderData, cash, restCash) => {
        dispatch({
            type: 'show_loader',
            payload: true
        });
        const customerDetails = JSON.parse(localStorage.getItem('customerDetail'));
        const shippingInfoRequestBody = {
            'addressInformation': {
                'shippingAddress': {
                    'region': agentDetails.store_region,
                    'region_id': agentDetails.store_region_id,
                    'country_id': agentDetails.store_country,
                    'street': [
                        agentDetails.store_street
                    ],
                    'telephone': customerDetails.contact_number ? customerDetails.contact_number : process.env.REACT_APP_DEFAULT_CONTACT_NUMBER,
                    'postcode': agentDetails.store_pincode,
                    'city': agentDetails.store_city,
                    'firstname': customerDetails.firstname,
                    'lastname': customerDetails.lastname,
                    'email': customerDetails.email,
                    'sameAsBilling': 1
                },
                'billingAddress': {
                    'region': agentDetails.store_region,
                    'region_id': agentDetails.store_region_id,
                    'country_id': agentDetails.store_country,
                    'street': [
                        agentDetails.store_street
                    ],
                    'telephone': customerDetails.contact_number ? customerDetails.contact_number : process.env.REACT_APP_DEFAULT_CONTACT_NUMBER,
                    'postcode': agentDetails.store_pincode,
                    'city': agentDetails.store_city,
                    'firstname': customerDetails.firstname,
                    'lastname': customerDetails.lastname,
                    'email': customerDetails.email,
                },
                'shipping_method_code': 'posshipping',
                'shipping_carrier_code': 'posshipping'
            }
        };
        if (agentDetails.store_region_id === '0') {
            delete shippingInfoRequestBody['addressInformation']['shippingAddress']['region_id'];
            delete shippingInfoRequestBody['addressInformation']['billingAddress']['region_id'];
        }

        let orderPaymentMethod = 'cashondelivery';
        if (localStorage.getItem('payment_mode') === 'card') {
            orderPaymentMethod = 'paybycard';
        }

        fetchPosMutation({
            mutation: PLACE_ORDER_MUTATION,
            variables: {
                payment_method: orderPaymentMethod,
                address_information: placeOrderData,
                cash,
                rest_cash: restCash,
            },
            notifyOnNetworkStatusChange: true,
            doAction: (response) => setOrderResponse(response.data),
        });
    };

    return {
        checkout,
    };
};

export default useCheckout;
