import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useAlert } from "react-alert";
import { REFUND_ORDER_QUERY } from '../../queries/salesOrderGraphqlschemas';
import { useQuery } from '@apollo/react-hooks';
import OrderViewSkeleton from "./OrderViewSkeleton";
import { orderFormatPrice } from '../../Utils';
import Loader from "../Loader/Loader";
import "./createRefund.css";

const CreateRefund = (props) => {
	const { orderId, showLoader, setIsCanCreditMemo } = props;
	const messageAlert = useAlert();
	const datavariables = { id: orderId };
	const loginToken = localStorage.getItem('login_token');
	const { loading, data } = useQuery(REFUND_ORDER_QUERY, {
		variables: datavariables,
		fetchPolicy: 'network-only',
		context: {
            headers: {
				'Store': localStorage.getItem('store_code'),
                'Content-Currency': localStorage.getItem('currency_code'),
                'Seller': loginToken,
            }
        },
	});

	const [totals, setTotals] = useState({subtotal: 0, grandTotal: 0, taxTotal: 0});
	const [refundItemsData, setRefundItemsData] = useState({adjustmentRefund: '', adjustmentFee: ''});

	const handleOnChange = (qty, value) => setRefundItemsData({
		...refundItemsData,
		[value.id]: qty <= +value.qty_refund
			? { qty, price: +value.price, tax: refundItemsData[value.id].tax }
			: { qty: +value.qty_refund, price: +value.price, tax: refundItemsData[value.id].tax },
	})

	const handleAdjustmentChange = (value, key) => {
		const numberRE = /^(?:[0-9]*\.?[0-9]{0,2})?$/;
		if (value && !numberRE.test(value)) {
			messageAlert.error("Please enter only number.");
		} else {
			setRefundItemsData({
				...refundItemsData,
				[key]: value,
			});
		}
	};

	const countData = (dataArray, param) => (
		Object.keys(dataArray).reduce((previous, key) => {
			if (key !== 'adjustmentRefund' && key !== 'adjustmentFee') {
				return previous + (dataArray[key].qty * dataArray[key][param]);
			}

			return previous;
		}, 0)
	);

	useEffect(() => {
		if (data && data.salesOrder) {
			const refundData = {};

			data.salesOrder.items.forEach(({ id, qty_refund: qty, price, tax_amount: tax}) => (
				refundData[id] = { qty, price, tax: tax / qty }
			));

			setRefundItemsData({...refundItemsData, ...refundData});

			setTotals({
				subtotal: countData(refundData, 'price'),
				grandTotal: Object.keys(refundData).reduce((previous, key) => (
					previous + (refundData[key].qty * refundData[key].price)
				), 0),
				taxTotal: countData(refundData, 'tax'),
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (data && Object.keys(refundItemsData).length) {
			setTotals({
				subtotal: countData(refundItemsData, 'price'),
				grandTotal: Object.keys(refundItemsData).reduce((previous, key) => {
					if (key !== 'adjustmentRefund' && key !== 'adjustmentFee') {
						return previous + (refundItemsData[key].qty * refundItemsData[key].price) + (refundItemsData[key].qty * refundItemsData[key].tax);
					}

					if (key === 'adjustmentFee') {
						return previous - (+refundItemsData[key]);
					}

					return previous + (+refundItemsData[key]);
				}, 0) - data.salesOrder.discount_amount_for_refund,
				taxTotal: countData(refundItemsData, 'tax'),
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refundItemsData]);

	if (typeof data != 'undefined') {
		setIsCanCreditMemo(data.salesOrder.can_creditmemo);

		if(data.salesOrder.can_creditmemo === 0 || data.salesOrder.pos === 0){
            return (
				<div className="no-customer-error">
					<img src="images/no-item.png" alt="images" width="40" />
					<p className="no-prduct-refund">
						{`Order with id ${orderId} and number ${data.salesOrder.increment_id} not eligible for credit memo`}
					</p>
				</div>
			);
		}else{
			localStorage.setItem('orderData', JSON.stringify(data.salesOrder));
			return (
				<form id="refund_form" method="post" >
					{showLoader && <Loader />}
					<div className="container-fluid p-0 pt-5">
						<div className="row p-0">
							<div className="col-6 text-right border-right my-1 order-info-label">
								<span className="order-info-label">Order ID : </span>
								<span>{data.salesOrder.increment_id}</span>
							</div>
							<div className="col-6 text-left">
								<span className="order-info-label">Order Date : </span>
								<span>{data.salesOrder.created_at}</span>
							</div>
						</div>
						<div className="row p-0">
							<div className="col-6 text-right border-right my-1 order-info-label">
								<span className="order-info-label">Customer Name :</span>
								<span>{data.salesOrder.customer_name}</span>

							</div>
							<div className="col-6 text-left">
								<span className="order-info-label">Customer Phone :</span>
								<span>{data.salesOrder.telephone}</span>
							  </div>
						</div>
						<div className="row p-0">
							<div className="col-6 text-right border-right my-1 order-info-label">
								<span className="order-info-label">Payment Mode : </span>
								<span>{data.salesOrder.payment}</span>
							</div>
							<div className="col-6 text-left">
								<span className="order-info-label">Invoice Number : </span>
								<span>{data.salesOrder.invoice_id}</span>
							  </div>
						</div>
						<div className="row p-0">
							<div className="col-12">
								<h5 className="order-items-title text-left mt-4 mb-0 px-3">Order Items</h5>
							</div>
						</div>
						<div className="row p-0">
							<div className="col-sm-12 itm-table">
								<table className="custom-table table order-specific-iems">
									<thead >
										<tr >
											<th scope="col">Name</th>
											<th scope="col">Price</th>
											<th scope="col">Returnable Quantity</th>
											<th scope="col">Quantity</th>
											<th scope="col">Sub Total</th>
											<th scope="col">Tax Amount</th>
											<th scope="col">Discount Amount</th>
											<th scope="col">Row Total</th>
										</tr>
									</thead>
									<tbody >
										{
											data.salesOrder.items.map((value, index) => (
												<tr key={index}>
													<td>{value.name}</td>
													<td>{orderFormatPrice(value.price, data.salesOrder.currency_code)}</td>
													<td>
														<input
															name={"item_id_" + value.id}
															type="text"
															value={refundItemsData[value.id] && refundItemsData[value.id].qty
																	? refundItemsData[value.id].qty : 0
																}
															onChange={(e) => handleOnChange(+e.target.value, value)}
														/>
													</td>
													<td>{value.qty_ordered}</td>
													<td>{orderFormatPrice(value.sub_total, data.salesOrder.currency_code)}</td>
													<td>{orderFormatPrice(value.tax_amount_for_refund, data.salesOrder.currency_code)}</td>
													<td>{orderFormatPrice(value.discount_amount_for_refund, data.salesOrder.currency_code)}</td>
													<td>{orderFormatPrice(value.row_total_for_refund, data.salesOrder.currency_code)}</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
						</div>
						<div className="row justify-content-end pr-3">
							<div className="col-6">
								<table className="refund-subtotal table subtotal-table">
									<tbody>
										<tr>
											<th className="sub-heading" scope="row">Sub-Total</th>
											<td>{orderFormatPrice(totals.subtotal, data.salesOrder.currency_code)}</td>
										</tr>
										<tr>
											<th className="sub-heading" scope="row">Adjustment Refund</th>
											<td>
												<input
													name="adjustment_refund"
													type="text"
													value={refundItemsData.adjustmentRefund}
													onChange={(e) => handleAdjustmentChange(e.target.value, 'adjustmentRefund')}
												/>
												<input type="hidden" name="order_id" defaultValue={props.orderId} />
											</td>
										</tr>
										<tr>
											<th className="sub-heading" scope="row">Adjustment Fee</th>
											<td>
												<input
													name="adjustment_fee"
													type="text"
													value={refundItemsData.adjustmentFee}
													onChange={(e) => handleAdjustmentChange(e.target.value, 'adjustmentFee')}
												/>
											</td>
										</tr>
										<tr>
											<th className="sub-heading" scope="row">Tax</th>
											<td >{orderFormatPrice(totals.taxTotal, data.salesOrder.currency_code)}</td>
										</tr>
										{
											data && data.salesOrder.discount_amount_for_refund ? (
												<tr>
													<th className="sub-heading" scope="row">Discount</th>
													<td >{orderFormatPrice(data.salesOrder.discount_amount_for_refund, data.salesOrder.currency_code)}</td>
												</tr>
											) : null
										}
										<tr>
											<th className="sub-heading total" scope="row">Total</th>
											<td className="total">{orderFormatPrice(totals.grandTotal, data.salesOrder.currency_code)}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</form>
			);
		}
	} else if(loading !== false) {
		return (
			<div>
				<OrderViewSkeleton />
			</div>
		);
	}else{
		return (
			<div className="no-customer-error">
				<img src="images/no-item.png" alt="images" width="40" />
				<p className="no-prduct-refund">{"Order with id "+ orderId + " doesn't exist."}</p>
			</div>
		);
	}
}

CreateRefund.propTypes = {
	orderId: PropTypes.number,
	showLoader: PropTypes.bool,
	setIsCanCreditMemo: PropTypes.func,
};

export default CreateRefund;
