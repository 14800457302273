export default {
    en_US: [
        "Item",
        "Item(s)",
        "Qty",
        "Amount",
        "Subtotal",
        "Discount",
        "Price",
        "Fee",
        "Tax",
        "Total",
        "Side Panel",
        "Recent Orders",
        "All Orders",
        "Daily cash register",
        "Shift opened",
        "Shift closed",
        "There is no opening shift",
        "Please open shift first",
        "Confirm",
        "Cancel",
        "Please confirm the closing of the shift.",
        "Please confirm the cash deposit.",
        "Orders",
        "Back to orders",
        "Create Refund",
        "Print Invoice",
        "No Orders Found",
        "Open shift",
        "Close shift",
        "Card payment",
        "Start the daily statement at the card terminal and enter the amount that is on it.",
        "System",
        "At the end of the day",
        "Deviation",
        "Cash",
        "Count how much money you have in the register and enter the amount WITHOUT the initial stick.",
        "Put the counted amount, including coins, (without the initial stock) in the save.",
        "Counted",
        "Total Deviation",
        "Deposit money",
        "Deposit",
        "To lock",
        "Deposit Cash",
        "Deposit cash",
        "Count the cash and make sure it matches the amount here exactly.",
        "If there is a discrepancy, contact the office.",
        "You can exchange the coin in the safe for notes from the till.",
        "Amount in Save",
        "Amount to Deposit",
        "First Name",
        "Last Name",
        "Contact Number",
        "Email",
        "Add Customer",
        'Add New Customer',
        "Close",
        "This is a required field.",
        "Please enter a valid email address (Ex: johndoe@domain.com).",
        "Log out",
        "Shopping Cart",
        "Add Custom Sale",
        "Download PDF",
        "Applied balance",
        "Current balance"
    ],
};
