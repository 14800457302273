import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import SmartDataTable from '../SmartTable/react-smart-data-table/lib/SmartDataTable';
import { useQuery } from '@apollo/react-hooks';
import MenuPopupWrap from "../MenuPopupWrapHOC";
import { __ } from '../Translations';
import OrderView from "../OrderView/OrderView";
import OrderSkeleton from "./orderSkeleton.js";
import Refund from "../Refund/refund";
import useDownloadPDF from '../DownloadPDF';
import { ORDERS_QUERY, AGENT_ORDERS_QUERY } from './ordersGraphqlSchemas';
import "./Orders.css";

const Orders = ({ onChange, isShowAllOrders }) => {
	const [orderViewId, setOrderViewId] = useState('');
	const [isRefund, setIsRefund] = useState(false);
	const [searchData, setSearchData] = useState({});
	const [isCanCreditMemo, setIsCanCreditMemo] = useState(true);
	const { startLoadAction, RenderPDFModal } = useDownloadPDF();
	const loginToken = localStorage.getItem('login_token');
	const variables = isShowAllOrders
		? {
			store_id: +localStorage.getItem('store_id'),
		}
		: {
			store_id: +localStorage.getItem('store_id'),
			created_at: new Date().toJSON().slice(0, 10),
		};

	const currentQuery = isShowAllOrders ? ORDERS_QUERY : AGENT_ORDERS_QUERY;
	const { loading, data, refetch } = useQuery(currentQuery, {
		variables,
		fetchPolicy: "network-only",
        context: { 
			headers: {
				'Store': localStorage.getItem('store_code'),
				'Content-Currency': localStorage.getItem('currency_code'),
				'Seller': loginToken,
			},
        },
		skip: orderViewId,
	});

	const tableData = [];

	const addDataToTable = (ordersData) => {
		ordersData.forEach(v => {
			tableData.push({
				order_id: v.increment_id,
				order_date: v.created_at,
				customer: v.customer_name,
				email: v.customer_email,
				total: v.grand_total,
				actions: v.id,
			});
		});
	};

	if (typeof data != 'undefined' && data.agentOrders && data.agentOrders.records) {
		addDataToTable(data.agentOrders.records);
	} else if (isShowAllOrders && typeof data != 'undefined' && data.allOrders && data.allOrders.records) {
		addDataToTable(data.allOrders.records);
	}

	const closePopup = () => {
		setOrderViewId('');
		onChange();
		localStorage.removeItem('orderIdForInvoice');
	};

	const handleOrderView = (e) => {
		setOrderViewId(e.target.id);
		localStorage.setItem('orderIdForInvoice', e.target.id);
	};

	useEffect(() => {
		refetch();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getHeaders = () => {
		return {
			order_id:{
				sortable: true,
				filterable: true,
			},
			actions: {
				text: 'Actions',
				sortable: true,
				filterable: true,
				transform: (value) => (
					<button
						id={value}
						className="btn btn-info"
						onClick={(e) => handleOrderView(e)}
					>
						View
					</button>
				)
			}
		}
	};

	const renderOrderData = () => {
		if (isRefund) {
			return (
				<Refund
					close={() => setIsRefund(false)}
					orderId={+orderViewId}
					setIsCanCreditMemo={setIsCanCreditMemo}
				/>
			);
		}

		return <OrderView orderId={orderViewId} setIsCanCreditMemo={setIsCanCreditMemo} />;
	};

	if (orderViewId) {
		return (
			<div className="orders-wrapper">
				<div className="overlay order-details-wrapper" onClick={closePopup}></div>
				<div className="order-modal-header d-flex justify-content-between align-items-center">
					<h1 className="mb-0">{__('Orders')}</h1>
					<span className="close-icon" onClick={closePopup}>X</span>
				</div>
				{orderViewId && renderOrderData()}
				<div className="order-modal-footer d-flex justify-content-end align-items-center">
					<button className="btn btn-secondary mr-3" onClick={() => { setOrderViewId("");}}>
						{__('Back to orders')}
					</button>
					{
						!!isCanCreditMemo && (
							<button
								className="print-button mr-3"
								onClick={() => setIsRefund(true) }
							>
								{__('Create Refund')}
							</button>
						)
					}
					<RenderPDFModal />
					{/* <a className="print-button mr-3" target="_blank" href="/inoviceSlip">
						{__('Print Invoice')}
					</a> */}
					<button onClick={startLoadAction} className="print-button mr-3">
						{__('Download PDF')}
					</button>
				</div>
			</div>
		);
	}

	return (
		<MenuPopupWrap onClose={closePopup} title={__('Orders')}>
			<div className="order-modal-body">
				{
					orderViewId === "" && (
						<SmartDataTable
							data={tableData}
							name="po-table"
							headers={getHeaders()}
							className="ui compact selectable table"
							sortable
							perPage={10}
							isShowSearch={isShowAllOrders}
							handleSerch={refetch}
							searchData={searchData}
							setSearchData={setSearchData}
							searchFields={[
								{title: "increment_id", placeholder: "Order ID"},
								{title: "created_at", placeholder: "Order date"},
								{title: "customer_name", placeholder: "Customer"},
								{title: "customer_email", placeholder: "Email"},
							]}
						/>
					)
				}
			</div>
			{data && tableData.length === 0 && (
				<div className="order-modal-body">
					<div>
						<p>{__('No Orders Found')}</p>
					</div>
				</div>
			)}
			{loading && (
				<div>
					<OrderSkeleton />
				</div>
			)}
		</MenuPopupWrap>
	);
};

Orders.propTypes = {
	onChange: PropTypes.func,
	isShowAllOrders: PropTypes.bool,
};

export default Orders;
