import { useApolloClient } from '@apollo/react-hooks';
import { useAlert } from 'react-alert';

const usePosApolloApi = () => {
    const client = useApolloClient();
    const messageAlert = useAlert();
    const token = localStorage.getItem('customerToken');
    const storage = localStorage.getItem('source_code');
    const storeMode = localStorage.getItem('storage_mode');
    const storeCode = localStorage.getItem('store_code');
    const loginToken = localStorage.getItem('login_token');
    const currency = localStorage.getItem('currency_code');

    const fetchPosQuery = ({
        query,
        variables,
        headers,
        notifyOnNetworkStatusChange = false,
        fetchPolicy = '',
        doAction = () => {},
        doError = () => {},
    }) => (
        client.query({
            query,
            variables,
            notifyOnNetworkStatusChange,
            fetchPolicy,
            context: {
                headers: {
                    'Content-Type': 'application/json',
                    'Store': storeCode,
                    'Storage-Mode': storeMode,
                    'Storage': storage,
                    'Authorization': 'Bearer ' + token,
                    'Seller': loginToken,
                    'Content-Currency': currency,
                    ...headers,
                }
            }
        }).then((response) => {
            doAction(response);
        
            return response;
        }).catch((error) => {
            messageAlert.error(error.message);
            doError();
        })
    );

    const fetchPosMutation = ({
        mutation,
        variables,
        headers,
        notifyOnNetworkStatusChange = false,
        doAction = () => {},
        doError = () => {},
    }) => (
        client.mutate({
            mutation,
            variables,
            notifyOnNetworkStatusChange,
            context: {
                headers: {
                    'Content-Type': 'application/json',
                    'Store': storeCode,
                    'Storage-Mode': storeMode,
                    'Storage': storage,
                    'Authorization': 'Bearer ' + token,
                    'Seller': loginToken,
                    'Content-Currency': currency,
                    ...headers,
                }
            },
        }).then((response) => {
            doAction(response);
        
            return response;
        }).catch((error) => {
            messageAlert.error(error.message);
            doError();
        })
    );

    return {
        fetchPosQuery,
        fetchPosMutation,
    }
};

export default usePosApolloApi;
