import React, {useState, useEffect, useContext} from "react";
import PropTypes from 'prop-types';
import {Form, Row, Col} from 'react-bootstrap';
import {useAlert} from "react-alert";
import {Store} from '../../Store';
import {__} from '../Translations';
import MenuPopupWrap from "../MenuPopupWrapHOC";
import ConfirmPopup from "../ConfirmPopup";
import "./popup.css";

const DailyReportPopup = ({onClose, closeShift, getDailyData}) => {
    const messageAlert = useAlert();
    const {state: {availableCurrencyCodes}} = useContext(Store);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [dataInput, setDataInput] = useState(
        {cash: [{grand_total: 0, currency: 'CHF'}], card: [{grand_total: 0, currency: 'CHF'}]}
    );
    const report = JSON.parse(localStorage.getItem('daily_report'));
    const totalCardViolation = {};
    const totalCashViolation = {};

    const handelPaymentFilter = (key) => report && report.data
        ? report.data.filter((item) => item.payment_method === key)
        : [{totals: [{grand_total: 0, currency: 'CHF'}]}];

    const [{totals: cardTotals}] = handelPaymentFilter('paybycard');
    const [{totals: cashTotals}] = handelPaymentFilter('cashondelivery');

    const handleOnChange = (e, currency) => {
        const numberRE = /^(?:[0-9]*\.?[0-9]{0,2})?$/;
        if (e.target.value && !numberRE.test(+e.target.value)) {
            messageAlert.error("Please enter only number.");
        } else {
            setDataInput({
                ...dataInput,
                [e.target.name]: [
                    ...dataInput[e.target.name].filter((i) => i.currency !== currency),
                    {grand_total: e.target.value, currency}
                ],
            });
        }
    };

    const handleSetStyles = (value) => {
        if (value > 0) {
            return 'daily-popup-green';
        }

        if (value < 0) {
            return 'daily-popup-red';
        }

        return null;
    };

    const handleCloseShift = () => {
        closeShift({
            totals: {
                cash: cashTotals.map(({grand_total, currency}) => ({amount: grand_total, currency})),
                card: cardTotals.map(({grand_total, currency}) => ({amount: grand_total, currency})),
            },
        }, getDailyData);
        setIsConfirmationOpen(false);
        onClose();
    };

    useEffect(() => {
        if (report && report.data) {
            setDataInput({
                card: cardTotals,
                cash: cashTotals,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderCardTotals = (arrData = []) => (
        arrData.map(({grand_total: total, currency}) => {
            const [card] = dataInput.card.filter((item) => item.currency === currency);
            const cardGrandTotal = (card ? card.grand_total - total : 0).toFixed(2);
            totalCardViolation[currency] = cardGrandTotal;

            return (
                <div className="daily-popup-math" key={currency}>
                    <div className="daily-popup-totals">
                        <div>{__('System')}: <span>{`${total.toFixed(2)} ${currency}`}</span></div>
                        <div>{__('Deviation')}:
                            <span className={handleSetStyles(card ? card.grand_total - total : 0)}>
							{`${cardGrandTotal} ${currency}`}
						</span>
                        </div>
                    </div>
                    <div className="daily-popup-inputs">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>{__('At the end of the day')}:</Form.Label>
                                    <Form.Control
                                        name="card"
                                        type="text"
                                        value={card ? card.grand_total : 0}
                                        onChange={(e) => handleOnChange(e, currency)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        })
    );

    return (
        <MenuPopupWrap onClose={onClose} title={__('Close shift')}>
            <div className="order-modal-body text-left">
                {
                    isConfirmationOpen && (
                        <ConfirmPopup
                            title={__('Confirm')}
                            description={__('Please confirm the closing of the shift.')}
                            closePopup={() => setIsConfirmationOpen(false)}
                            onConfirm={handleCloseShift}
                        />
                    )
                }
                <div className="daily-popup-wrap">
                    <h5>{__('Card payment')}</h5>
                    {__('Start the daily statement at the card terminal and enter the amount that is on it.')}
                    {renderCardTotals(cardTotals)}
                </div>
                <div className="daily-popup-wrap">
                    <h5>{__('Cash')}</h5>
                    {__('Count how much money you have in the register and enter the amount WITHOUT the initial stick.')}
                    <div>{__('Put the counted amount, including coins, (without the initial stock) in the save.')}</div>
                    {
                        cashTotals.map(({grand_total: total, currency}) => {
                            const [cash] = dataInput.cash.filter((item) => item.currency === currency);
                            const cashGrandTotal = (cash ? cash.grand_total - total : 0).toFixed(2);
                            totalCashViolation[currency] = cashGrandTotal;

                            return (
                                <div className="daily-popup-math" key={currency}>
                                    <div className="daily-popup-totals">
                                        <div>{__('System')}: <span>{`${total.toFixed(2)} ${currency}`}</span></div>
                                        <div>{__('Deviation')}:
                                            <span className={handleSetStyles(cash ? cash.grand_total - total : 0)}>
											{`${cashGrandTotal} ${currency}`}
										</span>
                                        </div>
                                    </div>
                                    <div className="daily-popup-inputs">
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{__('Counted')}:</Form.Label>
                                                    <Form.Control
                                                        name="cash"
                                                        type="text"
                                                        value={cash ? cash.grand_total : 0}
                                                        onChange={(e) => handleOnChange(e, currency)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="daily-popup-deviation">
                    <div>
                        {__('Total Deviation')}:
                        {
                            availableCurrencyCodes.map((code) => {
                                const total = (totalCardViolation[code] ? +totalCardViolation[code] : 0)
                                    + (totalCashViolation[code] ? +totalCashViolation[code] : 0);
                                const isShow = Boolean(totalCardViolation[code] || totalCashViolation[code]);

                                if (!isNaN(total) && isShow) {
                                    return (
                                        <span key={code}>
											{`${(total).toFixed(2)} ${code}`}
										</span>
                                    )
                                }

                                return null;
                            })
                        }
                    </div>
                    <button className="btn btn-primary" onClick={() => setIsConfirmationOpen(true)}>
                        {__('To lock')}
                    </button>
                </div>
            </div>
        </MenuPopupWrap>
    );
};

DailyReportPopup.propTypes = {
    onClose: PropTypes.func,
    closeShift: PropTypes.func,
    getDailyData: PropTypes.func,
};

export default DailyReportPopup;
