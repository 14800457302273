import { gql } from 'apollo-boost';

const response = `
    success
    message
`;

export const CREATE_CREDITMEMO_MUTATION = gql `
    mutation createCreditMemo($order_id: Int!, $itemData: String!, $adjustment_refund: Float!, $adjustment_fee: Float!) {
        createCreditMemo(order_id: $order_id, itemData: $itemData, adjustment_refund: $adjustment_refund, adjustment_fee: $adjustment_fee) {
            success
            creditmemo_id
        }
    }
`;

export const UPDATEQTY_MUTATION = gql `
    mutation updateQty($product_id: String!, $product_qty: String) {
        updateQty(product_id: $product_id, product_qty: $product_qty) {
            ${response}
        }
    }
`;

export const DAILY_SALES_INDICATOR = gql `
    query dailySalesIndicator {
        dailySalesIndicator {
            data {
                payment_method
                title
                totals {
                    grand_total
                    currency
                }
            }
        }
    }
`;

export const TRANSLATIONS = gql `
    query allTranslations($store_id: String!, $translate: String) {
        allTranslations(store_id: $store_id, translate: $translate) {
            translations
        }
    }
`;

export const CURRENCY = gql `
    query {
        currency {
            base_currency_code
            base_currency_symbol
            available_currency_codes
            exchange_rates {
                currency_to
                rate
            }
        }
    }
`;
