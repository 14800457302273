// import { useState } from 'react';
import { useAlert } from "react-alert";
import { useApolloClient } from '@apollo/react-hooks';
import { TRANSLATIONS } from '../../queries/useCustomGraphqlschemas';
import useShift from "../../talons/useShift";
import Locale from './enLocale';

const useTranslations = () => {
    const client = useApolloClient();
    const messageAlert = useAlert();
    const { shiftStatus } = useShift();
    // const [translateData, setTranslateData] = useState({});

    const getTranslations = (variables, headers) => {
        client.query({
            query: TRANSLATIONS,
            variables: {...variables, translate: JSON.stringify(Locale.en_US)},
            context: { headers },
        }).then((response) => {
            // setTranslateData(response.data.allTranslations.translations);
            localStorage.setItem('translations', response.data.allTranslations.translations);
            shiftStatus();
        })
        .catch((err) => messageAlert.error(err));
    }

    return {
        getTranslations,
        // translateData,
    };
};

export default useTranslations;
