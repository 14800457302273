import {gql} from 'apollo-boost';

const items = `
    id
    name
    price
    qty_ordered
    row_total
    tax_amount
`;

const orders = `
    id
    increment_id
    customer_name
    discount_amount
    can_creditmemo
    status
    grand_total
    created_at
    invoice_id
    subtotal
    telephone
    payment
    tax
    fee
    currency_code
`;

export const ORDERS_QUERY = gql`
    query getOrderDetails($id: Int!) {
        salesOrder(id: $id) {
            ${orders}
            additional_information
            items{
                ${items}
                tax_percent
                discount_amount
            }
        }
    }
`;

export const REFUND_ORDER_QUERY = gql`
	query getOrderDetails($id: Int!) {
		salesOrder(id: $id) {
            ${orders}
			tax_for_refund
			creditmemo_total
			creditmemo_subtotal
			discount_amount_for_refund
			pos
			items{
                ${items}
				qty_refund
				status
				row_total_for_refund
				sub_total
				tax_amount_for_refund
				discount_amount_for_refund
			}
		}
	}
`;