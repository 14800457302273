import React from 'react';
import PropTypes from 'prop-types';
import {
    Form, Dropdown, DropdownButton, Row, Col,
} from 'react-bootstrap';
import './CheckoutAddressForm.css';

const CheckoutAddressForm = ({
    deliveryAddress,
    setDeliveryAddress,
    validationErrors,
    errors,
    countries = [],
}) => {
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        const numberRE = /^([0-9]{0,20})?$|^$/;

		if (name === 'telephone' && numberRE.test(value)) {
            setDeliveryAddress({
                ...deliveryAddress,
                [name]: value,
            });

            delete validationErrors[name];
        }

        if (name !== 'telephone') {
            setDeliveryAddress({
                ...deliveryAddress,
                [name]: value,
            });

            delete validationErrors[name];
        }
    };

    const renderRequiredError = <span className='address-required-fild'>Required field</span>;
    const countryTitle = countries.filter((({value}) => value === deliveryAddress.country_id))[0].label;

    return (
        <form className='address-checkout'>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label><span>*</span>Full Name:</Form.Label>
                        <Form.Control
                            name="full_name"
                            type="text"
                            placeholder="Enter Full Name"
                            value={deliveryAddress.full_name}
                            onChange={handleOnChange}
                            isInvalid={errors.full_name}
                        />
                        {errors.full_name && renderRequiredError}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label><span>*</span>Tel:</Form.Label>
                        <Form.Control
                            name="telephone"
                            type="tel"
                            placeholder="Enter Telephone"
                            value={deliveryAddress.telephone}
                            onChange={handleOnChange}
                            isInvalid={errors.telephone}
                        />
                        {errors.telephone && renderRequiredError}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label><span>*</span>Country:</Form.Label>
                        <DropdownButton
                            title={countryTitle}
                            className="checkout-address-select"
                            variant="custom"
                        >
                            {
                                countries.map(({value, label}) => (
                                    <Dropdown.Item
                                        key={value}
                                        onClick={() => {
                                            setDeliveryAddress({
                                                ...deliveryAddress,
                                                country_id: value,
                                            }); 
                                        }}
                                    >
                                        {label}
                                    </Dropdown.Item>
                                ))
                            }
                        </DropdownButton>
                        {errors.country_id && renderRequiredError}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label><span>*</span>Postcode:</Form.Label>
                        <Form.Control
                            name="postcode"
                            type="text"
                            placeholder="Enter Postcode"
                            value={deliveryAddress.postcode}
                            onChange={handleOnChange}
                            isInvalid={errors.postcode}
                        />
                        {errors.postcode && renderRequiredError}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label><span>*</span>City:</Form.Label>
                        <Form.Control
                            name="city"
                            type="text"
                            placeholder="Enter City"
                            value={deliveryAddress.city}
                            onChange={handleOnChange}
                            isInvalid={errors.city}
                        />
                        {errors.city && renderRequiredError}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label><span>*</span>Street:</Form.Label>
                        <Form.Control
                            name="street"
                            type="text"
                            placeholder="Enter Street"
                            value={deliveryAddress.street}
                            onChange={handleOnChange}
                            isInvalid={errors.street}
                        />
                        {errors.street && renderRequiredError}
                    </Form.Group>
                </Col>
            </Row>
        </form>
    );
};

CheckoutAddressForm.propTypes = {
	deliveryAddress: PropTypes.shape({}),
	setDeliveryAddress: PropTypes.func,
    validationErrors: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    countries: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CheckoutAddressForm;
