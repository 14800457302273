import React, { useState ,useRef, useEffect, forwardRef } from "react";
import {useQuery} from '@apollo/react-hooks';
import html2pdf from "html-to-pdf-js";
import ReactDOM from 'react-dom';
import { ORDERS_QUERY } from '../../queries/salesOrderGraphqlschemas';
import { orderFormatPrice } from "../../Utils";
import { __ } from '../Translations';
import "./DownloadPDF.css";

const Modal = forwardRef(({ data, orderData }, ref) => {
    const domElement = document.getElementById('modal');
    let agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
    agentDetails['pos_settings'] = JSON.parse(agentDetails.pos_settings);

    let posLogo = agentDetails.pos_settings.receipt_logo;
    const { message } = JSON.parse(orderData.additional_information);

    if (!domElement || (!orderData && typeof orderData == 'undefined')) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className="invoice-wrapper">
            <table
                ref={ref}
                className="responsive table-wrapper"
                align="center"
                width="300"
                border="0"
                cellPadding="0"
                cellSpacing="0"
            >
                <tbody>
                {agentDetails.pos_settings.receipt_show_logo && posLogo && (
                    <tr>
                        <td>
                            <table cellSpacing="0" cellpadidng="0" border="0" align="center">
                                <tbody>
                                <tr>
                                    <td>
                                        <img className="responsive-img" src={posLogo} alt="logo" border="0"/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                )}
                {agentDetails.pos_settings.receipt_top_header_content && (
                    <tr>
                        <td>
                            <table cellSpacing="0" cellpadidng="0" border="0" align="center">
                                <tbody>
                                <tr>
                                    <td className="receipt-header-td" align="center">
                                        <div className="receipt-top-header-content">
                                            {agentDetails.pos_settings.receipt_top_header_content}
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                )}
                <tr>
                    <td>
                        <table
                            className="header-info responsive"
                            cellSpacing="0"
                            cellpadidng="0"
                            border="0"
                            width="100%"
                        >
                            <tbody>
                                {(agentDetails.pos_settings.receipt_show_outlet_name === 1) && (
                                    <tr>
                                        <td className="info-label">
                                            {agentDetails.pos_settings.receipt_outlet_label}{" : "}
                                        </td>
                                        <td className="info-value">
                                            {agentDetails.store_name}
                                        </td>
                                    </tr>
                                )}
                                {(agentDetails.pos_settings.receipt_show_pos_agent_name === 1) && (
                                    <tr>
                                        <td className="info-label">
                                            {agentDetails.pos_settings.receipt_pos_agent_label}{" : "}
                                        </td>
                                        <td className="info-value">
                                            {agentDetails.name}
                                        </td>
                                    </tr>
                                )}
                                {(agentDetails.pos_settings.receipt_show_customer_name === 1) && (
                                    <tr>
                                        <td className="info-label">
                                            {agentDetails.pos_settings.receipt_customer_name_label}{" : "}
                                        </td>
                                        <td className="info-value">
                                            {orderData.customer_name}
                                        </td>
                                    </tr>
                                )}
                                {(agentDetails.pos_settings.receipt_show_order_date === 1) && (
                                    <tr>
                                        <td className="info-label">
                                            {agentDetails.pos_settings.receipt_order_date_label}{" : "}
                                        </td>
                                        <td className="info-value">
                                            {orderData.created_at}
                                        </td>
                                    </tr>
                                )}
                                {(agentDetails.pos_settings.receipt_show_order_id === 1) && (
                                    <tr>
                                        <td className="info-label">
                                            {agentDetails.pos_settings.receipt_order_label}{" : "}
                                        </td>
                                        <td className="info-value">
                                            {orderData.increment_id}
                                        </td>
                                    </tr>
                                )}
                                {(agentDetails.pos_settings.receipt_show_invoice_id === 1) && (
                                    <tr>
                                        <td className="info-label">
                                            {agentDetails.pos_settings.receipt_invoice_label}{" : "}
                                        </td>
                                        <td className="info-value">
                                            {orderData.invoice_id}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table className="products-table responsive" cellSpacing="0" cellpadidng="0" border="0"
                                width="100%">
                            <thead>
                            <tr>
                                <th className="table-title">{__('Item(s)')}</th>
                                <th className="table-title">{__('Qty')}</th>
                                <th className="table-title">{__('Amount')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orderData.items.map((value) => {
                                return (
                                    <tr key={value.id}>
                                        <td className="table-value">{value.name}</td>
                                        <td className="table-value">{value.qty_ordered}</td>
                                        <td className="table-value">{value.row_total.toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table className="responsive header-info" width="100%" cellSpacing="0" cellpadidng="0"
                                border="0">
                            <tbody>
                            <tr>
                                <td className="subtotal-label">{__(agentDetails.pos_settings.subtotal_label)}</td>
                                <td className="subtotal-value">
                                    {orderFormatPrice(orderData.subtotal, orderData.currency_code)}
                                </td>
                            </tr>
                            {
                                data && orderData.discount_amount ? (
                                    <tr>
                                        <td className="subtotal-label">{__(agentDetails.pos_settings.discount_label)}</td>
                                        <td className="subtotal-value">
                                            {orderFormatPrice(orderData.discount_amount, orderData.currency_code)}
                                        </td>
                                    </tr>
                                ) : null
                            }
                            {
                                data && orderData.fee ? (
                                    <tr>
                                        <td className="subtotal-label">{__('Fee')}</td>
                                        <td className="subtotal-value">
                                            {orderFormatPrice(orderData.fee, orderData.currency_code)}
                                        </td>
                                    </tr>
                                ) : null
                            }
                            <tr>
                                <td className="subtotal-label">{__(agentDetails.pos_settings.tax_label)}</td>
                                <td className="subtotal-value">
                                    {orderFormatPrice(orderData.tax, orderData.currency_code)}
                                </td>
                            </tr>
                            <tr>
                                <td className="total-label">{__(agentDetails.pos_settings.grand_total_label)}</td>
                                <td className="total-value">
                                    {orderFormatPrice(orderData.grand_total, orderData.currency_code)}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                {
                    message && (
                        <tr>
                            <td className="message-content">
                                🪧 {message}
                            </td>
                        </tr>
                    )
                }
                {agentDetails.pos_settings.receipt_footer_content && (
                    <tr>
                        <td>
                            <table className="responsive thank-you-section">
                                <tbody>
                                <tr>
                                    <td className="thank-you-content">
                                        {agentDetails.pos_settings.receipt_footer_content}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>,
        domElement,
    );
});

export default () => {
    const invoiceRef = useRef();
    const [isVisible, setIsVisible] = useState(false);
    const [filename, setFileName] = useState(null);
    const placedOrderId = localStorage.getItem('orderIdForInvoice');
    const loginToken = localStorage.getItem('login_token');
    let orderData;
    const datavariables = {
        id: +placedOrderId,
    };

    const { data, refetch } = useQuery(ORDERS_QUERY, {
        variables: datavariables,
        context: {
            headers: {
                'Store': localStorage.getItem('store_code'),
                'Seller': loginToken,
                'Content-Currency': localStorage.getItem('currency_code'),
            }
        },
        skip: !placedOrderId,
        fetchPolicy: "network-only"
    });

    if (placedOrderId && typeof data != 'undefined') {
        orderData = data.salesOrder;
    } else {
        orderData = JSON.parse(localStorage.getItem('orderData'));
    }

    const show = () => { refetch().then(({ data })=> {
        if (data && data.salesOrder.increment_id) {
            setFileName(data.salesOrder.increment_id);
        }
        setIsVisible(true);
    })};

    const createPDF = () => {
        const dH = invoiceRef && invoiceRef.current ? +invoiceRef.current.clientHeight + 60 : 400
        const dW = invoiceRef && invoiceRef.current ? +invoiceRef.current.clientWidth + 20 : 405
        const orientation = invoiceRef && invoiceRef.current && invoiceRef.current.clientHeight < 401 ? 'l' : 'p';

        const options = {
            margin: 1,
            filename,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 15,
                letterRendering: 1,
                allowTaint : true,
                useCORS: true,
            },
            jsPDF: { unit: 'px', format: [dH, dW], orientation },
        };

        html2pdf(invoiceRef.current, options);
    };

    useEffect(() => {
        let timer = null;

        if(isVisible && invoiceRef && invoiceRef.current) {
            createPDF();
            timer = setTimeout(() => setIsVisible(false), 1000);
        }

        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    const RenderPDFModal = () => isVisible ? <Modal data={data} orderData={orderData} ref={invoiceRef} /> : null;

    return {
        startLoadAction: show,
        RenderPDFModal,
    };
};
