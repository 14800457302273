import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { gql } from 'apollo-boost';
import { Store } from '../../Store';
import Loader from '../Loader/Loader';
import '../Pos/pos.css';

export const CUSTOMER_LIST = gql`
    query getCustomerList($customer: String, $store_id: String, $pos_store_name: String) {
        customerList(customer: $customer, store_id: $store_id, pos_store_name: $pos_store_name) {
            data {
                id
                name
                email
                contact_number
            }
        }
    }
`;

const CustomerSearch = (props) => {
    const [refreshPage, setRefreshPage] = useState(false);
    const { state, dispatch } = useContext(Store);
    const loginToken = localStorage.getItem('login_token');
    const storeId = localStorage.getItem('store_id');
    const storeName = localStorage.getItem('store_name');
    const storage = localStorage.getItem('source_code');
    const storeMode = localStorage.getItem('storage_mode');
    const store = localStorage.getItem('store_code');

    if(state.checkOutSuccess){
        localStorage.removeItem('customerName');
        localStorage.removeItem('customerId');
        localStorage.removeItem('customerToken');
        if(refreshPage !== true){
            setRefreshPage(true);
        }

        dispatch({
            type: 'checkout_success',
            payload: false
        });
    }

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    });

    let customerCount = 0;
    const {
        placeholder,
        noItemFound,
        setCustomerData,
        showLoader,
        customer,
        setCustomer,
    } = props;
    const [searchValue, setSearchValue] = useState('');
    const [searchKey, setSearchKey] = useState(false);
    const [suggestion, setSuggestion] = useState(false);

    const { data, refetch } = useQuery(CUSTOMER_LIST, {
        variables: {
            customer: searchValue,
            store_id: storeId,
            pos_store_name: storeName
        },
        context: {
            headers: {
                'Seller': loginToken,
                'Storage': storage,
                'Storage-Mode': storeMode,
                'Store': store,
                'Content-Currency': localStorage.getItem('currency_code'),
            }
        }
    });

    const currentCustomer = localStorage.getItem('customerName')
        ? localStorage.getItem('customerName')
        : 'Set Customer';

    if(customer !== currentCustomer) {
        setCustomer(currentCustomer);
    }

    if (typeof data != 'undefined') {
        customerCount = data.customerList.data.length;
    }

    const getCustomerSuggetion = (e) => {
        if (e.target.value) {
            setSearchKey(true);
        } else {
            setSearchKey(false);
        }

        e.persist();

        setTimeout(() => {
            setSearchValue(e.target.value);
        }, 1000);

        setSuggestion(true);
    };

    const handleClick = (e) => {
        if (
            e.target.className === 'cross-img'
            || e.target.className === 'search-img'
            || e.target.id === 'customersearch'
            || e.target.name === 'customersearch'
            || e.target.id === 'search-span'
            || e.target.id === 'search-icon'
        ) {
            setSuggestion(true);
        } else {
            setSuggestion(false);
        }
    };

    const showSuggestion = () => {
        setSearchValue('');
        setSuggestion(true);
    };

    const handleSetCustomer = (customer) => {
        if (!currentCustomer.startsWith(customer.name)) {
            setCustomerData(customer);
            setSuggestion(false);
        }
    };

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'customer_list',
                payload: data.customerList.data,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (!localStorage.getItem('customerToken') && data && state.isShiftOpen) {
            setCustomerData(data.customerList.data[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isShiftOpen, data]);

    useEffect(() => {
        if(state.customerUpdate) {
            refetch();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customerUpdate]);

    return (
        <div  className="customer_search_wrap" >
            {showLoader && <Loader />}
            <div className="d-flex justify-content-between" onClick={showSuggestion} id="customersearch">
                <span id="search-span">{customer}</span>
                <img id="search-icon" className="mt-2" alt="no-item" src="images/caret-down.png" width="9" height="9" />
            </div>
            {suggestion && (
                <div className="customer_search position-relative">
                    <input className="mb-1" name="customersearch" id="search-field" placeholder={placeholder} onKeyUp={getCustomerSuggetion} />
                    {!searchKey && (
                        <img className="search-img" src="images/item-search.png" alt="search" width="15" height="15" />
                    )}
                    {searchKey && (
                        <img className="customer-cross-img" src="images/cancel.png" alt="cross" width="15" height="15" onClick={() => {
                            document.getElementById('search-field').value="";
                            setSearchKey(false);
                    }}/>
                    )}
                    {typeof data != 'undefined' && suggestion && (
                        <ul className="set-cutomer-list p-2">
                            {data && data.customerList.data.map((customer, index) => {
                                var name = customer.name;
                                if (customer.email) {
                                    name += " - " + customer.email;
                                }
                                if (customer.contact_number) {
                                    name += " - " + customer.contact_number;
                                }
                                return (
                                    <li
                                        key={index}
                                        onClick={() => handleSetCustomer(customer)}
                                        id={customer.id}
                                        value={name}
                                    >
                                        {name}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    {customerCount === 0 && (
                        <ul className="set-cutomer-list p-2">
                            <li className="no-customer-error">
                                <img src="images/no-item.png" alt="no-item" />
                                <p className="pt-2">{noItemFound}</p>
                            </li>
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
}

CustomerSearch.propTypes = {
    placeholder: PropTypes.node,
    noItemFound: PropTypes.node,
    setCustomerData: PropTypes.func,
	showLoader: PropTypes.bool,
	customer: PropTypes.string,
	setCustomer: PropTypes.func,
};

export default CustomerSearch;
