import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import GiftCardsList from './GiftCardsList';
import { Store } from '../../Store';

const DiscountBlock = (props) => {
    const {
        discount,
        discountResponse,
        discountRemoveResponse,
        removeDiscountFromCart,
        applyDiscount,
        discountCode,
        setDiscountCode,
        discountLabel,
        placeholder,
        isGiftCard,
    } = props;
	const { state } = useContext(Store);
    const [discountToggle, setDiscountToggle] = useState(false);
    const removed_response = localStorage.getItem(discountRemoveResponse);

    useEffect(() => {
        if (removed_response) {
            setDiscountToggle(false);
            // localStorage.removeItem(discountRemoveResponse);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removed_response]);

    const handleOnChange = (e) => {
        setDiscountCode(e.target.value);
    };

    const handleApplyDiscount = () => {
        if (isGiftCard) {
            setDiscountCode('');
        }

        applyDiscount();
    };

    useEffect(() => {
        if (state.checkOutSuccess) {
            setDiscountToggle(false);
            localStorage.removeItem('gift_card_response');
            localStorage.removeItem('discount_response');
            localStorage.removeItem('coupon_response');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.checkOutSuccess]);

    const renderInput = () => (
        <div className="apply-coupon-wrap">
            <div className="d-flex justify-content-between apply-coupon-text">
                <span className="manage-font">{discountLabel}</span>
                <span
                    className="add-coupon-img"
                    onClick={() => setDiscountToggle(!discountToggle)}
                >
                    <img
                        className="plus-icon"
                        src="images/plus.png"
                        alt="img" width="20"
                        height="20"
                    />
                </span>
            </div>
            <div className={discountToggle ? "hide-input show-input" : "hide-input"}>
                <span className="back-img">
                    <img
                        className="img-fluid"
                        alt="images"
                        src="images/back.png"
                        width="20"
                        height="20"
                        onClick={() => setDiscountToggle(!discountToggle)}
                    />
                </span>
                <input
                    name="cart_coupon"
                    placeholder={placeholder}
                    type="text"
                    onChange={handleOnChange}
                    {...{value: isGiftCard ? discountCode : undefined}}
                />
                <button className="apply-coupon-btn" onClick={handleApplyDiscount}>Apply</button>
            </div>
        </div>
    );

    return (
        <li className="p-0 list-items list-unstyled">
            {discount && !isGiftCard && (
                <div className="entered-coupon">
                    <span className="correct-img-wrap">
                        <img src="images/correct-icon.png" alt="correct-coupon" width="20" height="20" />
                    </span>
                    <input
                        name="cart_coupon"
                        type="text"
                        disabled="disabled"
                        value={discount}
                    />
                    <button className="cancel-button" onClick={removeDiscountFromCart}>Cancel</button>
                </div>
            )}
            {!discount && !isGiftCard && renderInput()}
            {isGiftCard && renderInput()}
            {discountResponse && discountResponse !== true && discountResponse !== 'true' && !isGiftCard && (
                <span className="coupon-response">{discountResponse}</span>
            )}
            {isGiftCard && <GiftCardsList removeGiftCard={removeDiscountFromCart} />}
        </li>
    );
};

DiscountBlock.propTypes = {
    discount: PropTypes.string,
    discountResponse: PropTypes.string,
    discountRemoveResponse: PropTypes.string,
    removeDiscountFromCart: PropTypes.func,
    applyDiscount: PropTypes.func,
    discountCode: PropTypes.string,
    setDiscountCode: PropTypes.func,
    discountLabel: PropTypes.string,
    placeholder: PropTypes.string,
    isGiftCard: PropTypes.bool,
};

export default DiscountBlock;
