import { gql } from 'apollo-boost';

const money = `
    currency
    value
`;

export const DISCOUNT = gql `
    mutation posDiscount($discount_value: String!, $item_id: Int) {
        posDiscount(discount_value: $discount_value, item_id: $item_id) {
            success
        }
    }
`;

export const SHIPPING = gql `
    mutation setShippingInformation ($input: SetShippingInformation) {
        setShippingInformation(input: $input) {
            cart_id
        }
    }
`;

export const APPLY_GIFT_CARD = gql `
    mutation applyAmGiftCardToCart ($input: ApplyAmGiftCardToCartInput) {
        applyAmGiftCardToCart(input: $input) {
            cart {
                applied_am_gift_cards {
                    code
                    expiration_date
                    current_balance {
                        ${money}
                    }
                    applied_balance {
                        ${money}
                    }
                }
            }
        }
    }
`;

export const REMOVE_GIFT_CARD = gql `
    mutation removeAmGiftCardFromCart ($input: RemoveAmGiftCardFromCartInput) {
        removeAmGiftCardFromCart(input: $input) {
            cart {
                applied_am_gift_cards {
                    code
                    expiration_date
                    current_balance {
                        ${money}
                    }
                    applied_balance {
                        ${money}
                    }
                }
            }
        }
    }
`;

const customerCart = `
    id
    total_quantity
    items {
        id
        uid
        quantity
        prices {
            price {
                ${money}
            }
            price_including_tax {
                ${money}
            }
            row_total {
                ${money}
            }
            row_total_including_tax {
                ${money}
            }
            discounts {
                amount {
                    ${money}
                }
            }
            total_item_discount {
                ${money}
            }
            item_discount {
                ${money}
            }
            tax_amount {
                ${money}
            }
        }
        product {
            id
            name
            sku
        }
        options {
            label
            value
        }
        errors {
            code
            message
        }
    }
    prices {
        grand_total {
            ${money}
            }
        subtotal_including_tax {
            ${money}
            }
        subtotal_excluding_tax {
            ${money}
            }
        subtotal_with_discount_excluding_tax {
            ${money}
        }
        tax_amount {
            ${money}
        }
        fee {
            ${money}
        }
        discount_amount {
            ${money}
        }
        pos_discount_amount {
            ${money}
        }
    }
    applied_coupons {
        code
    }
    applied_am_gift_cards {
        code
        expiration_date
        current_balance {
            ${money}
        }
        applied_balance {
            ${money}
        }
    }
    amasty_rewards_used_points {
        used_points
    }
`;

export const CART = gql `
    query customerCart {
        customerCart {
            ${customerCart}
        }
    }
`;

export const ADD_SIMPLE_PRODUCT_MUTATION = gql `
    mutation addSimpleProductsToCart($input: AddSimpleProductsToCartInput) {
        addSimpleProductsToCart(input: $input) {
            cart {
                ${customerCart}
            }
        }
    }
`;

export const REMOVE_ITEM_FROM_CART = gql `
    mutation removeItemFromCart($input: RemoveItemFromCartInput) {
        removeItemFromCart(input: $input) {
            cart {
                ${customerCart}
            }
        }
    }
`;

export const UPDATE_QTY_CART = gql `
    mutation updateCartItems($input: UpdateCartItemsInput) {
        updateCartItems(input: $input) {
            cart {
                ${customerCart}
            }
        }
    }
`;

export const APPLY_COUPON_TO_CART = gql `
    mutation applyCouponToCart($input: ApplyCouponToCartInput) {
        applyCouponToCart(input: $input) {
            cart {
                ${customerCart}
            }
        }
    }
`;

export const REMOVE_COUPON_FROM_CART = gql `
    mutation removeCouponFromCart($input: RemoveCouponFromCartInput) {
        removeCouponFromCart(input: $input) {
            cart {
                ${customerCart}
            }
        }
    }
`;
