import { gql } from 'apollo-boost';

const response = `
    success
    message
`;

export const OPEN_SHIFT = gql `
    mutation openShift {
        openShift {
            ${response}
        }
    }
`;

export const CLOSE_SHIFT = gql `
    mutation closeShift($totals: ShiftTotals!) {
        closeShift(totals: $totals) {
            ${response}
        }
    }
`;

export const SHIFT_STATUS = gql `
    query shiftStatus {
        shiftStatus {
            status
            message
        }
    }
`;