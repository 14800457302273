// Import modules
import React from 'react';
import PropTypes from 'prop-types';
// Import components
import ErrorBoundary from './ErrorBoundary';
import TableCell from './TableCell';
import Toggles from './Toggles';
import Paginator from './Paginator';
import withPagination from './with-pagination';
import defaultClasses from "./smartTable.css";
// Import functions
import {
    debugPrint,
    errorPrint,
    fetchData,
    parseDataForColumns,
    parseDataForRows,
    sliceRowsPerPage,
    sortData,
    isEmpty,
    isString,
    isFunction
} from './helpers/functions';
import OrderDatePicker from '../../../OrderDatePicker';
// Import styles
import basic from './css/basic.css';

class SmartDataTablePlain extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            asyncData: [],
            columns: [],
            colProperties: {},
            sorting: {
                key: '',
                dir: ''
            },
            activePage: 1,
            isLoading: false
        };

        this.handleColumnToggle = this.handleColumnToggle.bind(this);
        this.handleOnPageChange = this.handleOnPageChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { filterValue } = props;
        const { prevFilterValue } = state;
        if (filterValue !== prevFilterValue) {
            return {
                activePage: 1,
                prevFilterValue: filterValue,
            };
        }
        return null;
    }

    componentDidMount() {
        this.showWarnings();
        this.fetchData();
        this.setColProperties();
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        const { data: prevData } = prevProps;
        if (
            isString(data) &&
            (typeof data !== typeof prevData || data !== prevData)
        ) {
            this.fetchData();
        }
    }

    setColProperties() {
        const { headers } = this.props;
        this.setState({ colProperties: headers });
    }

    fetchData() {
        const { data, dataKey } = this.props;
        if (isString(data)) {
            this.setState({ isLoading: true });
            fetchData(data, dataKey)
                .then(asyncData => {
                    this.setState({
                        asyncData,
                        isLoading: false,
                        columns: this.getColumns(true)
                    });
                })
                .catch(debugPrint);
        }
    }

    showWarnings() {
        const { footer, withHeaders } = this.props;
        const propError = (oldName, newName) => `
[SmartDataTable] The '${oldName}' prop has been deprecated in v0.6 and is no
longer valid. Consider replacing it with '${newName}'
`;
        if (footer) errorPrint(propError('footer', 'withFooter'));
        if (withHeaders) errorPrint(propError('withHeaders', 'withHeader'));
    }

    handleRowClick(event, rowData, rowIndex, tableData) {
        const { onRowClick } = this.props;
        if (onRowClick) {
            onRowClick(event, { rowData, rowIndex, tableData });
        }
    }

    handleColumnToggle(key) {
        const { colProperties } = this.state;
        if (!colProperties[key]) {
            colProperties[key] = {};
        }
        colProperties[key].invisible = !colProperties[key].invisible;
        this.setState({ colProperties });
    }

    handleOnPageChange(event, { activePage }) {
        this.setState({ activePage });
    }

    handleSortChange(column) {
        const { sorting } = this.state;
        const { key } = column;
        let dir = '';
        if (key !== sorting.key) sorting.dir = '';
        if (sorting.dir) {
            if (sorting.dir === 'ASC') {
                dir = 'DESC';
            } else {
                dir = '';
            }
        } else {
            dir = 'ASC';
        }
        this.setState({
            sorting: {
                key,
                dir
            }
        });
    }

    renderSorting(column) {
        const {
            sorting: { key, dir }
        } = this.state;
        let sortingIcon = basic.rsdt_sortable_icon;
        if (key === column.key) {
            if (dir) {
                if (dir === 'ASC') {
                    sortingIcon = basic.rsdt_sortable_asc;
                } else {
                    sortingIcon = basic.rsdt_sortable_desc;
                }
            }
        }
        return (
            <i
                className={basic.rsdt + ' ' + sortingIcon}
                onClick={() => this.handleSortChange(column)}
                onKeyDown={() => this.handleSortChange(column)}
                role="button"
                tabIndex={0}
            />
        );
    }

    renderHeader(columns) {
        const { colProperties } = this.state;
        const { sortable } = this.props;
        const headers = columns.map(column => {
            const thisColProps = colProperties[column.key];
            const showCol = !thisColProps || !thisColProps.invisible;
            if (showCol) {
                return (
                    <th key={column.key}>
                        <span>{column.text}</span>
                        <span className={basic.rsdt + ' ' + basic.rsdt_sortable}>
                            {sortable && column.sortable
                                ? this.renderSorting(column)
                                : null}
                        </span>
                    </th>
                );
            }
            return null;
        });

        return <tr>{headers}</tr>;
    }

    renderRow(columns, row, i) {
        const { colProperties } = this.state;
        const { withLinks, filterValue, parseBool, parseImg } = this.props;

        return columns.map((column, j) => {
            const thisColProps = Object.assign({}, colProperties[column.key]);
            const showCol = !thisColProps.invisible;
            const transformFn = thisColProps.transform;

            if (showCol) {
                return (
                    <td
                        className={defaultClasses.column_data}
                        key={`row-${i}-column-${j}`}
                        data-attribute={column.key}
                    >
                        {isFunction(transformFn) ? (
                            transformFn(row[column.key], i, row)
                        ) : (
                            <ErrorBoundary>
                                <TableCell
                                    withLinks={withLinks}
                                    filterValue={filterValue}
                                    parseBool={parseBool}
                                    parseImg={parseImg}
                                    filterable={thisColProps.filterable}
                                    isImg={thisColProps.isImg}
                                >
                                    {row[column.key]}
                                </TableCell>
                            </ErrorBoundary>
                        )}
                    </td>
                );
            }
            return null;
        });
    }

    renderBody(columns, rows) {
        const {
            perPage,
            isShowSearch,
            handleSerch,
            searchData,
            setSearchData,
            searchFields = [],
        } = this.props;
        const { activePage } = this.state;
        const visibleRows = sliceRowsPerPage(rows, activePage, perPage);
        const tableRows = visibleRows.map((row, i) => (
            <tr
                key={`row-${i}`}
                onClick={e => this.handleRowClick(e, row, i, rows)}
            >
                {this.renderRow(columns, row, i)}
            </tr>
        ));

        return (
            <tbody>
                {
                    isShowSearch && (
                        <tr className='border-bottom'>
                            {
                                searchFields.map(({ title, placeholder }) => (
                                    <td key={title}>
                                        {
                                            title === 'created_at'
                                                ? (
                                                    <OrderDatePicker
                                                        onChange={setSearchData}
                                                        searchData={searchData}
                                                        placeholder={placeholder}
                                                    />
                                                )
                                                : (
                                                    <input
                                                        className="form-control"
                                                        title={title}
                                                        placeholder={placeholder}
                                                        type="text"
                                                        value={searchData[title] || ''}
                                                        onChange={
                                                            (e) => setSearchData(
                                                                { ...searchData, [title]: e.target.value }
                                                            )
                                                        }
                                                    />
                                                )
                                        }
                                    </td>
                                ))
                            }
                            <td>
                                <button className='print-button' onClick={() => handleSerch(searchData)}>Search</button>
                            </td>
                            <td>
                                <button
                                    className='btn btn-secondary'
                                    onClick={() => { setSearchData({}); handleSerch({
                                            increment_id: '',
                                            customer_name: '',
                                            customer_email: '',
                                            from: '',
                                            to: '',
                                        });
                                    }}
                                >
                                    Clear filters
                                </button>
                            </td>
                        </tr>
                    )
                }
                {tableRows}
            </tbody>
        );
    }

    renderFooter(columns) {
        const { withFooter } = this.props;
        return withFooter ? this.renderHeader(columns) : null;
    }

    renderToggles(columns) {
        const { colProperties } = this.state;
        const { withToggles } = this.props;
        return withToggles ? (
            <ErrorBoundary>
                <Toggles
                    columns={columns}
                    colProperties={colProperties}
                    handleColumnToggle={this.handleColumnToggle}
                />
            </ErrorBoundary>
        ) : null;
    }

    renderPagination(rows) {
        const { perPage, paginator: PaginatorComponent } = this.props;
        const { activePage } = this.state;
        const Paginate = withPagination(PaginatorComponent);
        return perPage && perPage > 0 ? (
            <ErrorBoundary>
                <Paginate
                    rows={rows}
                    perPage={perPage}
                    activePage={activePage}
                    onPageChange={this.handleOnPageChange}
                />
            </ErrorBoundary>
        ) : null;
    }

    getColumns(force = false) {
        const { asyncData, columns } = this.state;
        const { data, headers } = this.props;

        if (!force && !isEmpty(columns)) {
            return columns;
        }

        if (isString(data)) {
            return parseDataForColumns(asyncData, headers);
        }

        return parseDataForColumns(data, headers);
    }

    getRows() {
        const { asyncData, colProperties, sorting } = this.state;
        const { data, filterValue } = this.props;
        if (isString(data)) {
            return sortData(
                filterValue,
                colProperties,
                sorting,
                parseDataForRows(asyncData)
            );
        }
        return sortData(
            filterValue,
            colProperties,
            sorting,
            parseDataForRows(data)
        );
    }

    render() {
        const {
            name,
            className,
            withHeader,
            loader,
            dynamic,
            isShowSearch,
            emptyTable,
        } = this.props;
        const { isLoading } = this.state;
        const columns = this.getColumns(dynamic);
        const rows = this.getRows();
        if (isEmpty(rows) && !isShowSearch) return emptyTable;

        if (isLoading) {
            return loader;
        }

        const ordersColumns = [
            { key: "order_id", text: "Order Id" },
            { key: "order_date", text: "Order Date" },
            { key: "customer", text: "Customer" },
            { key: "email", text: "Email" },
            { key: "total", text: "Total" },
            { key: "actions", text: "Actions" },
        ];

        return (
            <div className="order_table_inner">
                {this.renderToggles(columns)}
                <table data-table-name={name} className={className}>
                    {withHeader && <thead>{this.renderHeader(isShowSearch ? ordersColumns : columns)}</thead>}
                    {this.renderBody(columns, rows)}
                    <tfoot>{this.renderFooter(columns)}</tfoot>
                </table>
                {this.renderPagination(rows)}
            </div>
        );
    }
}

// Defines the type of data expected in each passed prop
SmartDataTablePlain.propTypes = {
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    dataKey: PropTypes.string,
    columns: PropTypes.array,
    name: PropTypes.string,
    sortable: PropTypes.bool,
    withToggles: PropTypes.bool,
    withLinks: PropTypes.bool,
    withHeader: PropTypes.bool,
    withFooter: PropTypes.bool,
    filterValue: PropTypes.string,
    perPage: PropTypes.number,
    className: PropTypes.string,
    loader: PropTypes.node,
    onRowClick: PropTypes.func,
    parseBool: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    parseImg: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    headers: PropTypes.object,
    dynamic: PropTypes.bool,
    emptyTable: PropTypes.node,
    paginator: PropTypes.func
};

// Defines the default values for not passing a certain prop
SmartDataTablePlain.defaultProps = {
    dataKey: 'data',
    columns: [],
    name: 'reactsmartdatatable',
    sortable: false,
    withToggles: false,
    withLinks: false,
    withHeader: true,
    withFooter: false,
    filterValue: '',
    perPage: 0,
    className: '',
    loader: null,
    parseBool: false,
    parseImg: false,
    headers: {},
    dynamic: false,
    emptyTable: null,
    paginator: Paginator
};

// Wrap the component with an Error Boundary
const SmartDataTable = props => (
    <ErrorBoundary>
        <SmartDataTablePlain {...props} />
    </ErrorBoundary>
);

export default SmartDataTable;
