import React from 'react';
import PropTypes from 'prop-types';
import { generatePagination, isUndefined } from './helpers/functions';
import './css/paginator.css';

const PaginatorItem = ({ active, value, text, onPageChange }) => (
    <button className={`pagination_btns ${active ? ' active ' : ' '}`}
        type="button"
        onClick={e => onPageChange(e, { activePage: value })}
        disabled={isUndefined(value)}
    >
        {text}
    </button>
);

const Paginator = props => {
    const { activePage, totalPages, onPageChange } = props;
    const paginatorItems = generatePagination(activePage, totalPages);
    return (
        <div className="pagination-wrapper">
            {paginatorItems.map(({ active, value, text }, idx) => (
                <PaginatorItem
                    key={isUndefined(value) ? `ellipsis-${idx}` : text}
                    active={active}
                    value={value}
                    text={text}
                    onPageChange={onPageChange}
                />
            ))}
        </div>
    );
};

Paginator.propTypes = {
    activePage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func
};

PaginatorItem.propTypes = {
    active: PropTypes.bool.isRequired,
    value: PropTypes.number,
    text: PropTypes.string.isRequired,
    onPageChange: PropTypes.func.isRequired
};

export default Paginator;
