import { useEffect, useState } from 'react';
import { usePosApolloApi } from '../posApolloAPI';
import { CUSTOMERTOKEN_MUTATION } from '../queries/customerTokenSchema';
import useRewardPoints from './useRewardPoints';
import useCart from './useCart';

export const useCustomer = () => {
    const { fetchPosMutation } = usePosApolloApi();
    const { getCustomerCart } = useCart();
    const { getRewardPoints } = useRewardPoints();
    const [customerData, setCustomerData] = useState();

    const getCustomerToken = (details) => {
        return fetchPosMutation({
            mutation: CUSTOMERTOKEN_MUTATION,
            variables: {
                customerId: details.customerId,
                warehouseId: details.warehouseId,
                fromQuoteId: details.fromQuoteId
            },
            doAction: (response) => {
                localStorage.setItem('customerToken', response.data.customerToken.token);
                localStorage.setItem('quote_id', response.data.customerToken.quote_id);
                localStorage.setItem('cart_hash', response.data.customerToken.cart_hash);
                setCustomerData(response.data);
            },
        });
    };

    useEffect(() => {
        if (customerData && customerData.customerToken && customerData.customerToken.token) {
            getCustomerCart();
            getRewardPoints();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData]);

    return {
        customerData,
        getCustomerToken,
    };
};