/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState } from "react";
import CustomerForm from "./CustomerForm/CustomerForm";
import { __ } from '../Translations';
import "../Pos/pos.css";

const AddCustomer = () => {
    const [showPopup, setShowPopup] = useState(false);
  
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };
  
    return (
        <div className="add-customer-wrapper">
            <a  href="#"
                className="info-button d-flex align-items-center justify-content-between"
                onClick={togglePopup}
                >										
                <span className="set-customer">{__('Add Customer')}</span>
                <img className="Cart-icon" src="images/plus.png" alt="img"/>
            </a>
            {showPopup ?
                <CustomerForm
                    text={__('Add New Customer')}
                    closePopup={togglePopup}
                    openPopup={showPopup}
                />
                : null
            }
        </div>
    );
    
}

export default AddCustomer;