import { gql } from 'apollo-boost';

export const AUTHORIZATION_LOGOUT = gql`
    mutation agentLogout {
        agentLogout {
            success
            message
        }
    }
`;

export const AGENTLOGIN_MUTATION = gql`
    mutation agentLogin($username: String!, $password: String!) {
        agentlogin(username: $username, password: $password) {
            success,
            id,
            name,
            email,
            contact_number,
            country,
            region,
            region_id,
            city,
            street,
            pincode,
            profile_picture,
            store_id,
            warehouse_id,
            store_name,
            store_country,
            store_region_id,
            store_region,
            store_street,
            store_city,
            store_pincode,
            currency_code,
            currency_symbol,
            cashondelivery,
            paybycard,
            cashondelivery_title,
            paybycard_title,
            paybycard_services,
            pos_settings,
            source_code,
            login_token,
            store_code,
            store_category_id,
            storage_modes { mode, label, message },
            countries
        }
    }
`;
