/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState } from "react";
import ProductForm from "./ProductForm/productForm";
import { __ } from '../Translations';
import "../Pos/pos.css";

const AddProduct = () => {
    const [showPopup, setShowPopup] = useState(false);
    const quoteId = localStorage.getItem('quote_id');

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <div className="add-customer-wrapper">
            <a href="#" onClick={togglePopup} className={`info-button d-flex align-items-center justify-content-between${!quoteId ? ' disable': ''}`}>
                <span className="set-customer">{__('Add Custom Sale')}</span>
                <img className="Cart-icon" src="images/plus.png" alt="img" width="20" />
            </a>
            {showPopup && quoteId
                ? (
                    <ProductForm
                        text={__('Add Custom Sale')}
                        closePopup={togglePopup}
                        isOpenPopup={showPopup}
                    />
                )
                : null
            }
        </div>
    );
};

export default AddProduct;
