import React, {useContext, useEffect} from 'react';
import $ from 'jquery';
import { Store } from '../../Store';
import { Form, Dropdown, DropdownButton } from 'react-bootstrap';
import './modeSwitcher.css';

const ModeSwitcher = () => {
    const { state, dispatch } = useContext(Store);
    const modes = localStorage.getItem('storage_modes')
        ? JSON.parse(localStorage.getItem('storage_modes')) : [];
    const mode = localStorage.getItem('storage_mode');

    const clickHandle = (value) => {
        if (value !== mode) {
            localStorage.setItem('storage_mode', value);
            dispatch({type: 'store_mode', payload: value});
        }
    };

    useEffect(() => {
        if (mode) {
            dispatch({type: 'store_mode', payload: mode});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [item] = modes.filter((item) => item.mode === mode);

    useEffect(() => {
        if (item.label === 'POS') {
            $('.switch-wrap .checkout-address-select > button').css('background-color','#2a9937');
        } else {
            $('.switch-wrap .checkout-address-select > button').css('background-color','#ce393a');
        }
    }, [item]);

    return (
        <div className="switch-wrap">
            <Form.Group>
                <DropdownButton
                    title={item.label}
                    className="checkout-address-select"
                    variant="custom"
                >
                    {
                        state.storeMode && modes.map(({label, mode }) => (
                            <Dropdown.Item
                                key={mode}
                                onClick={() => clickHandle(mode)}
                            >
                                {label}
                            </Dropdown.Item>
                        ))
                    }
                </DropdownButton>
            </Form.Group>
        </div>
    );
};

export default ModeSwitcher;
