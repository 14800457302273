import React from "react";
import ContentLoader from 'react-content-loader';

function Skelton() {
  return (
        <ContentLoader
            height={350}
            width={305}
            speed={.8}
            primaryColor="#E8E8E8"
            secondaryColor="#FFFFFF"
        >
            <rect height="70" x="5" width="70" y="5" />
            <rect height="5" x="5" width="60" y="76" />
            <rect height="5" x="5" width="45" y="82" />
            <rect height="5" x="5" width="30" y="88" />

            <rect height="70" x="80" width="70" y="5" />
            <rect height="5" x="80" width="60" y="76" />
            <rect height="5" x="80" width="45" y="82" />
            <rect height="5" x="80" width="30" y="88" />

            <rect height="70" x="155" width="70" y="5" />
            <rect height="5" x="155" width="60" y="76" />
            <rect height="5" x="155" width="45" y="82" />
            <rect height="5" x="155" width="30" y="88" />

            <rect height="70" x="230" width="70" y="5" />
            <rect height="5" x="230" width="60" y="76" />
            <rect height="5" x="230" width="45" y="82" />
            <rect height="5" x="230" width="30" y="88" />




            <rect height="70" x="5" width="70" y="99" />
            <rect height="5" x="5" width="60" y="170" />
            <rect height="5" x="5" width="45" y="176" />
            <rect height="5" x="5" width="30" y="182" />

            <rect height="70" x="80" width="70" y="99" />
            <rect height="5" x="80" width="60" y="170" />
            <rect height="5" x="80" width="45" y="176" />
            <rect height="5" x="80" width="30" y="182" />

            <rect height="70" x="155" width="70" y="99" />
            <rect height="5" x="155" width="60" y="170" />
            <rect height="5" x="155" width="45" y="176" />
            <rect height="5" x="155" width="30" y="182" />

            <rect height="70" x="230" width="70" y="99" />
            <rect height="5" x="230" width="60" y="170" />
            <rect height="5" x="230" width="45" y="176" />
            <rect height="5" x="230" width="30" y="182" />




            <rect height="70" x="5" width="70" y="195" />
            <rect height="5" x="5" width="60" y="266" />
            <rect height="5" x="5" width="45" y="272" />
            <rect height="5" x="5" width="30" y="278" />

            <rect height="70" x="80" width="70" y="195" />
            <rect height="5" x="80" width="60" y="266" />
            <rect height="5" x="80" width="45" y="272" />
            <rect height="5" x="80" width="30" y="278" />

            <rect height="70" x="155" width="70" y="195" />
            <rect height="5" x="155" width="60" y="266" />
            <rect height="5" x="155" width="45" y="272" />
            <rect height="5" x="155" width="30" y="278" />

            <rect height="70" x="230" width="70" y="195" />
            <rect height="5" x="230" width="60" y="266" />
            <rect height="5" x="230" width="45" y="272" />
            <rect height="5" x="230" width="30" y="278" />

        </ContentLoader>
  		);
}
export default Skelton;
