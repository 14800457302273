import React, { useContext } from "react";
import PropTypes from 'prop-types';
import { Store } from "../../Store";

export const formattedExchangePrice = ({ value, isSymbolRight, state }) => {
    const { currencyCode, exchangeRates } = state;
    const { rate } = exchangeRates.filter((rate) => rate.currency_to === currencyCode)[0] || {rate: 1};

    return isSymbolRight
        ? `${(value * rate).toFixed(2)} ${currencyCode}`
        : `${currencyCode} ${(value * rate).toFixed(2)}`;
};

const Price = ({ isCurrencyRight, className, value }) => {
    const { state } = useContext(Store);

    return (
        <span className={className}>
            {formattedExchangePrice({ value, isSymbolRight: isCurrencyRight, state })}
        </span>
    );
};

Price.propTypes = {
	isCurrencyRight: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.number,
};

export default Price;