import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {__} from '../../Translations';
import { formattedPrice } from '../../../Utils';
import './giftCardsList.css';

const GiftCardsListItem = ({
    removeGiftCard,
    code,
    amount,
    cardCurrency,
}) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <div
            className='gift-cards-list-item'
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <span>{code}</span>
            <span className="remove-gift-card">
                <img
                    className="img-fluid"
                    alt="images"
                    src="images/cancel.png"
                    width="10"
                    height="10"
                    onClick={() => removeGiftCard({am_gift_card_code: code})}
                />
            </span>
            {
                isHover && (
                    <div className='gift-card-info'>
                        <div>{__('Applied balance')}:<div>{formattedPrice(amount, true)}</div></div>
                    </div>
                )
            }
        </div>
    );
};

GiftCardsListItem.propTypes = {
    removeGiftCard: PropTypes.func,
    code: PropTypes.string,
    amount: PropTypes.number,
    cardCurrency: PropTypes.string,
};

export default GiftCardsListItem;
