import { gql } from 'apollo-boost';

const address = `
    country_id,
    full_name,
    city,
    street,
    postcode,
    telephone
`;

export const CUSTOMERTOKEN_MUTATION = gql`
    mutation customerToken($customerId: String!, $warehouseId: String!, $fromQuoteId: Int) {
        customerToken(customer_id: $customerId, warehouse_id: $warehouseId, from_quote_id: $fromQuoteId) {
            token
            quote_id
            cart_hash
            billing {
                ${address}
            }
            shipping {
                ${address}
            }
        }
    }
`;
