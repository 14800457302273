import React from "react";
import { Switch,BrowserRouter } from "react-router-dom";
import Login from './Components/Login';
import Pos from './Components/Pos';
// import Orders from './Components/Orders';
// import InvoicePrint from './Components/InvoicePrint';

import AppliedRoute from "./Components/AppliedRoute";

export default function Routes({appProps}) {
  return (
    <BrowserRouter>
      <Switch>
        <AppliedRoute path="/" exact component={Login} appProps={appProps} />
        <AppliedRoute path="/pos" exact component={Pos} appProps={appProps} />
        {/* <AppliedRoute path="/inoviceSlip" exact component={InvoicePrint} appProps={appProps} /> */}
      </Switch>
    </BrowserRouter>
  );
}

