import React, { createContext, useReducer } from 'react';

export const Store = createContext();

const initialState = {
  episodes: [],
  favourites: [],
  cartItems: {},
  showLoader: false,
  showCartLoader: false,
  checkOutSuccess: false,
  productUpdate: false,
  rewardPoints: {},
  storeMode: '',
  qtyError: false,
  customerList: {},
  isShiftOpen: false,
  currencyCode: 'CHF',
  exchangeRates: [],
  availableCurrencyCodes: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_DATA':
      return { ...state, episodes: action.payload };
    case 'cart_items':
      return { ...state, cartItems: action.payload };
    case 'show_loader':
      return { ...state, showLoader: action.payload };
    case 'show_cart_loader':
      return { ...state, showCartLoader: action.payload };
    case 'checkout_success':
      return { ...state, checkOutSuccess: action.payload };
    case 'product_update':
      return { ...state, productUpdate: action.payload };
    case 'customer_update':
      return { ...state, customerUpdate: action.payload };
    case 'get_reward_points':
      return { ...state, rewardPoints: {
        ...state.rewardPoints,
        availablePoints: action.payload
      }};
    case 'store_mode':
      return { ...state, storeMode: action.payload};
    case 'qty_error':
        return { ...state, qtyError: action.payload};
    case 'customer_list':
      return { ...state, customerList: action.payload};
    case 'open_shift':
      return { ...state, isShiftOpen: action.payload };
    case 'currency_code':
        return { ...state, currencyCode: action.payload };
    case 'exchange_rates':
        return { ...state, exchangeRates: action.payload };
    case 'available_currency_codes':
      return { ...state, availableCurrencyCodes: action.payload };
    default:
      return state;
  }
};

export const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <Store.Provider value={value}>{props.children}</Store.Provider>;
};