import { gql } from 'apollo-boost';

export const GET_REWARD_POINTS = gql `
    query rewards {
        rewards {
            balance
            amount {
                value
                currency
            }
        }
    }
`;

export const USE_REWARD_POINTS = gql `
    mutation useRewardPoints($input: UseRewardPointsInput) {
        useRewardPoints(input: $input) {
            response
        }
    }
`;
