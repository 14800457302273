import { gql } from 'apollo-boost';

export const SAFE_DEPOSIT = gql `
    mutation safeDeposit {
        safeDeposit {
            success
            message
        }
    }
`;

export const SAFE_TOTALS = gql `
    query safeTotals {
        safeTotals {
            data {
                amount
                deposit_amount
                currency
            }
        }
    }
`;
