import { useContext } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { useAlert } from 'react-alert';
import { Store } from '../Store';
import { OPEN_SHIFT, CLOSE_SHIFT, SHIFT_STATUS } from '../queries/shiftSchemas';

const useShift = () => {
    const token = localStorage.getItem('login_token');
    const storage = localStorage.getItem('source_code');
    const storageMode = localStorage.getItem('storage_mode');
    const messageAlert = useAlert();
    const { dispatch } = useContext(Store);
    const client = useApolloClient();
    const mutationConfig = {
        context: {
            headers: {
                'Seller': localStorage.getItem('login_token'),
                'Storage': storage,
                'Storage-Mode': storageMode,
                'Authorization': 'Bearer ' + token,
                'Store': localStorage.getItem('store_code'),
                'Content-Currency': localStorage.getItem('currency_code')
            }
        },
    };

    const [sendOpenShift] = useMutation(OPEN_SHIFT, mutationConfig);
    const [sendCloseShift] = useMutation(CLOSE_SHIFT, mutationConfig);

    const openShift = async () => {
        dispatch({
            type: 'show_loader',
            payload: true
        });

        const responseData = {};

        try {
            responseData.data = await sendOpenShift().then(({ data }) => {
                if (data && data.openShift.message) {
                    messageAlert.success(data.openShift.message);
                }

                if (data && data.openShift.success) {
                    localStorage.setItem('shift', 'Open');
                }
            });
        } catch (error) {
            messageAlert.error(error.message);
        }
        dispatch({
            type: 'show_loader',
            payload: false
        });
    };

    const closeShift = async (variables, dailyDataFunc) => {
        dispatch({
            type: 'show_loader',
            payload: true
        });

        const responseData = {};

        try {
            responseData.data = await sendCloseShift({ variables }).then(({
                data
            }) => {
                if (data && data.closeShift.message) {
                    messageAlert.success(data.closeShift.message);
                }

                if (data && data.closeShift.success) {
                    localStorage.removeItem('shift');
                    dailyDataFunc();
                    dispatch({
                        type: 'open_shift',
                        payload: false
                    });
                }
            });
        } catch (error) {
            messageAlert.error(error.message);
        }
        dispatch({
            type: 'show_loader',
            payload: false
        });
    };

    const shiftStatus = (variables) => {
        client.query({
                query: SHIFT_STATUS,
                variables,
                context: {
                    headers: {
                        'Seller': localStorage.getItem('login_token'),
                        'Storage': storage,
                        'Storage-Mode': storageMode,
                        'Authorization': 'Bearer ' + token,
                        'Store': localStorage.getItem('store_code'),
                        'Content-Currency': localStorage.getItem('currency_code')
                    }
                },
            }).then(({ data }) => {
                if (data && data.shiftStatus.status) {
                    localStorage.setItem('shift', 'Open');
                    dispatch({
                        type: 'open_shift',
                        payload: true
                    });
                }
            })
            .catch((err) => messageAlert.error(err));
    };

    return {
        openShift,
        closeShift,
        shiftStatus,
    };
};

export default useShift;
