import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import "./loader.css";

const Loader = ({isAbsolute}) => {
    const [active, setActive] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setActive(false);
        }, 3000);

		return () => clearTimeout(timer);
	}, []);

	const cls = isAbsolute ? "absolute-loader" : null;

  return (
        <div className={active ? `loader-overlay active-loader${' ' + cls}` : `loader-overlay${' ' + cls}`}>
	      <div className="intro-banner-vdo-play-btn pinkBg">
				<span className="ripple pinkBg"></span>
				<span className="ripple pinkBg"></span>
				<span className="ripple pinkBg"></span>
	      </div>
        </div>
	);
};

Loader.propTypes = {
    isAbsolute: PropTypes.bool,
};

export default Loader;
