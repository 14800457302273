import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import {useAlert} from "react-alert";
import {usePosApolloApi} from '../../posApolloAPI';
import {SAFE_TOTALS, SAFE_DEPOSIT} from "./depositGraphqlSchemas";
import {__} from '../Translations';
import MenuPopupWrap from "../MenuPopupWrapHOC";
import ConfirmPopup from "../ConfirmPopup";
import "./depositMoney.css";

const DailyReportPopup = ({onClose}) => {
    const messageAlert = useAlert();
    const {fetchPosQuery, fetchPosMutation} = usePosApolloApi();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [data, setData] = useState();
    const [response, setResponse] = useState();

    const getDepositData = () => {
        return fetchPosQuery({
            query: SAFE_TOTALS,
            fetchPolicy: "network-only",
            doAction: (response) => {
                setData(response.data.safeTotals.data);
            },
        })
    };

    const handleDepositMoney = () => {
        setIsConfirmationOpen(false);

        return fetchPosMutation({
            mutation: SAFE_DEPOSIT,
            doAction: (response) => {
                setResponse(response.data.safeDeposit);
            },
        })
    };

    useEffect(() => {
        getDepositData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (response) {
            if (response.success) {
                messageAlert.success(response.message);
                getDepositData();
            } else {
                messageAlert.show(response.message);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    const renderTotals = (amount, deposit, currency) => (
        <div className="deposit-money-totals" key={currency}>
            <div className="deposit-money-amount">
                {__('Amount in Save')}:
                <span>{`${amount.toFixed(2)} ${currency}`}</span>
            </div>
            <div className="deposit-money-amount">
                {__('Amount to Deposit')}:
                <span>{`${deposit.toFixed(2)} ${currency}`}</span>
            </div>
        </div>
    );

    return (
        <MenuPopupWrap onClose={onClose} title={__('Deposit money')}>
            <div className="order-modal-body text-center">
                {
                    isConfirmationOpen && (
                        <ConfirmPopup
                            title={__('Confirm')}
                            description={__('Please confirm the cash deposit.')}
                            closePopup={() => setIsConfirmationOpen(false)}
                            onConfirm={handleDepositMoney}
                        />
                    )
                }
                <div className="deposit-money-wrap">
                    <h5>{__('Deposit Cash')}</h5>
                    <img src="images/safe-open.png" alt="safe"/>
                    {
                        data ? data.map(({amount, deposit_amount: deposit, currency}) => (
                            renderTotals(amount, deposit, currency)
                        )) : renderTotals(0, 0, 'CHF')
                    }
                    <div>{__('Count the cash and make sure it matches the amount here exactly.')}</div>
                    <div>{__('You can exchange the coin in the safe for notes from the till.')}</div>
                    <div className="deposit-money-info">*{__('If there is a discrepancy, contact the office.')}</div>
                    <button className="btn btn-primary" onClick={() => setIsConfirmationOpen(true)}>
                        {__('Deposit')}
                    </button>
                </div>
            </div>
        </MenuPopupWrap>
    );
};

DailyReportPopup.propTypes = {
    onClose: PropTypes.func,
};

export default DailyReportPopup;
